import React from 'react';
import { connect } from 'react-redux';
import ArchivedUserList from './ArchivedUserList';
import { userListFetch, userPasswordReset, userRestore } from '../../actions/userList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  users: state.userList.users,
  isLoading: state.userList.isLoading,
  error: state.userList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: criteria => dispatch(
    userListFetch({
      ...criteria,
      archived: true,
    }),
  ),
  onReset: id => dispatch(userPasswordReset(id)),
  onToggle: id => dispatch(userRestore(id)),
});

const ArchivedUserListContainer = props => (
  <Screen name="users" isLoading={false}>
    <ArchivedUserList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchivedUserListContainer);
