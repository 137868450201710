import { push } from 'connected-react-router';
import {
  LOGIN_FINISHED, SESSION_START, SESSION_LOAD_FINISHED, LOGOUT,
} from '../actionTypes';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
const relevantActions = [
  LOCATION_CHANGE,
  SESSION_START,
  SESSION_LOAD_FINISHED,
  LOGIN_FINISHED,
  LOGOUT,
];
const guestRoutes = ['/'];

/**
 * Redirects users based on their permissions
 * users: prevent from going to guest routes
 * guests: prevent from going to protected routes
 */
export default store => next => (action) => {
  next(action);

  // force logout on 401
  if (action.error && action.error.response && action.error.response.status === 401) {
    store.dispatch(push('/logout'));
    return;
  }

  if (relevantActions.indexOf(action.type) !== -1) {
    const {
      auth: { loading, account },
      router: {
        location: { pathname },
      },
    } = store.getState();

    // prevent lossy redirects
    // ie "/authed-page" -> login -> dashboard
    if (loading) {
      return;
    }

    const isGuestRoute = guestRoutes.some(route => pathname === route);

    if (account && isGuestRoute) {
      store.dispatch(push('/dashboard'));
    }
    if (!account && !isGuestRoute) {
      store.dispatch(push('/'));
    }
  }
};
