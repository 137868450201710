import React from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import { loginStart, consumeFlashMessage } from '../../actions';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const { clearFlashMessage } = this.props;
    clearFlashMessage();
  }

  handleSubmit = async (data) => {
    const { onSubmit } = this.props;
    try {
      this.setState({ error: null });
      await onSubmit(data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;
    const message = this.props.location.state ? this.props.location.state.message : null;
    return <Login {...this.props} message={message} error={error} onSubmit={this.handleSubmit} />;
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  onSubmit: ({ email, password }) => dispatch(loginStart(email, password)),
  clearFlashMessage: () => dispatch(consumeFlashMessage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);
