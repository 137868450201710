import React from 'react';
import { connect } from 'react-redux';
import ChangePassword from './ChangePassword';
import { changePassword } from '../../actions';

class ChangePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  handleSubmit = async (data) => {
    const { onSubmit } = this.props;
    try {
      this.setState({ error: null });
      await onSubmit(data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;
    return <ChangePassword {...this.props} onSubmit={this.handleSubmit} error={error} />;
  }
}

const mapStateToProps = state => ({
  required: state.auth.account && state.auth.account.isTemporaryPassword,
});

const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line max-len
  onSubmit: ({ currentPassword, newPassword, confirmPassword }) => dispatch(changePassword(currentPassword, newPassword, confirmPassword)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordContainer);
