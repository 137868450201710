import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileItem from '../../components/ProfileItem';
import { orgPropType } from '../../propTypes';

const OrgProfile = ({ org }) => (
  <div className="org-information">
    <h2>Organization Profile</h2>
    <Row>
      <Col xs={3}>
        <ProfileItem label="Legal Name" value={org.legalName} />
        <ProfileItem label="Contact Email" value={org.email} />
        <ProfileItem label="Admin Name" value={org.adminName} />
      </Col>
      <Col xs={3}>
        <ProfileItem label="Common Name" value={org.commonName} />
        <ProfileItem label="Contact Phone Number" value={org.phoneNumber} />
        <ProfileItem label="Admin Phone Number" value={org.adminPhoneNumber} />
      </Col>
      <Col xs={3}>
        <ProfileItem label="Contact Name" value={org.contact} />
        <ProfileItem label="Address" value={org.address} />
        <ProfileItem label="Admin Email" value={org.adminEmail} />
      </Col>
    </Row>
  </div>
);
OrgProfile.propTypes = {
  org: orgPropType.isRequired,
};
export default OrgProfile;
