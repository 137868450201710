import React from 'react';
import PropTypes from 'prop-types';

class AppError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error: propsError, children } = this.props;
    const { error: stateError, errorInfo } = this.state;
    const error = stateError || propsError;
    const debug = true;

    if (error) {
      const style = {
        width: '800px',
        margin: '0 auto',
      };
      return (
        <div style={style}>
          <h2>Something Went Wrong</h2>
          <p>{error.message}</p>
            {errorInfo && debug && <pre>{errorInfo.componentStack}</pre>}
            {error && debug && <pre>{error.stack}</pre>}
        </div>
      );
    }

    return children;
  }
}

AppError.defaultProps = {
  error: null,
};

AppError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppError;
