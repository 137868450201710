import fileDownload from 'js-file-download';
import { push } from 'connected-react-router';
import { CONTAINER_EXPORT_FINISHED, CONTAINER_EXPORT_FAILED } from '../actionTypes';

const containerExportFinished = () => ({
  type: CONTAINER_EXPORT_FINISHED,
});

const containerExportFailed = error => ({
  type: CONTAINER_EXPORT_FAILED,
  error,
});

export const containerExport = (initialDate, finalDate, exportCompletedContainers) => async (dispatch, getState, { api }) => {
  try {
    const criteria = {
      initialDate,
      finalDate,
      exportCompletedContainers
    };

    const containers = await api.exportContainers(criteria);

    fileDownload(containers.data, 'containerExport.csv');

    dispatch(containerExportFinished());
    dispatch(push('/analytics'));
  } catch (e) {
    dispatch(containerExportFailed(e));
  }
};
