import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrgTable from './OrgTable';

const OrgList = ({ fetchOrgs, onToggle }) => (
  <div className="org-list">
    <h1>Organizations</h1>
    <div className="actions">
      <ul className="nav">
        <li>
          <Link to="/organizations/archived" className="btn secondary">
            View Archived List
          </Link>
        </li>
        <li>
          <Link to="/organizations/add" className="btn btn-primary">
            Add Organization
          </Link>
        </li>
      </ul>
    </div>
    <OrgTable fetchOrgs={fetchOrgs} onToggle={onToggle} archived={false} />
  </div>
);

OrgList.propTypes = {
  fetchOrgs: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default OrgList;
