import React from 'react';
import { connect } from 'react-redux';
import OrgList from './OrgList';
import { orgListFetch, orgArchive } from '../../actions/orgList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  orgs: state.orgList.orgs,
  isLoading: state.orgList.isLoading,
  error: state.orgList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrgs: criteria => dispatch(
    orgListFetch({
      ...criteria,
      archived: false,
    }),
  ),
  onToggle: id => dispatch(orgArchive(id)),
});

const OrgListContainer = props => (
  <Screen name="orgs" isLoading={false}>
    <OrgList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgListContainer);
