import React from 'react';
import { connect } from 'react-redux';
import OrgAdd from './OrgAdd';
import Screen from '../../components/Screen';
import { orgAdd } from '../../actions/orgAdd';

const mapStateToProps = state => ({
  isLoading: state.orgAdd.isLoading,
  error: state.orgAdd.error,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(orgAdd(data)),
});

class OrgAddContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  handleSubmit = async (data) => {
    const { onSubmit } = this.props;
    try {
      await onSubmit(data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { isLoading, error: propError } = this.props;
    const { error: stateError } = this.state;
    return (
      <Screen name="org-add" isLoading={isLoading} error={propError}>
        <OrgAdd {...this.props} onSubmit={this.handleSubmit} error={stateError} />
      </Screen>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgAddContainer);
