import React from 'react';
import { Route } from 'react-router-dom';
import can from '../utilities/can';

const RoutePermissioned = ({
  component: Component, render, permission, user, ...rest
}) => (
  <Route
    {...rest}
    render={props => (can(permission, user) ? (
      render ? (
        render(props)
      ) : (
        <Component {...props} />
      )
    ) : (
      <div>
        <h1>Access Denied</h1>
        <p>You do not have permission to access this page.</p>
      </div>
    ))
    }
  />
);

export default RoutePermissioned;
