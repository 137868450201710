import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ProgressBar.scss';

const ProgressBar = ({ steps }) => {
  const { length } = Object.keys(steps);
  const stepInfo = steps.map((step, i) => ({
    ...step,
    first: i === 0,
    last: i === length - 1,
    nextDisabled: !steps[i + 1] || steps[i + 1].disabled,
  }));

  return (
    <div className="progress-node-bar">
      {stepInfo.map(({
        label, selected, disabled, nextDisabled, first, last, url,
      }) => (
        <div
          className={[
            'node',
            first ? 'first' : '',
            last ? 'last' : '',
            selected ? 'selected' : '',
            disabled ? 'disabled' : '',
            nextDisabled ? 'next-disabled' : '',
          ].join(' ')}
          key={label}
          title={label}
        >
          <div className="circle" />
          <Link to={url}>{label}</Link>
        </div>
      ))}
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      disabled: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProgressBar;
