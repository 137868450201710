import React from 'react';
import PropTypes from 'prop-types';

const columns = 12;
const gutterWidth = 30;
const gutterHalfWidth = gutterWidth / 2;

const columnWidth = (cols, pageWidth) => {
  if (!pageWidth) {
    throw new Error('Missing pageWidth');
  }
  const contentWidth = pageWidth - (gutterWidth * columns);
  const singleColumnWidth = contentWidth / columns;
  const totalColumnsSize = singleColumnWidth * cols;
  return (totalColumnsSize) + (gutterWidth * (cols - 1));
};

const columnWidthPadded = (cols, pageWidth) => columnWidth(cols, pageWidth) + gutterWidth;

const printDebug = (width) => {
  let out = `
    .grid-helper-cell {
      width: ${columnWidth(1, width)}px;
    }
  `;
  for (let i = 1; i <= 12; i += 1) {
    if (i === 1) {
      out += `
        #col${i} {
          margin-left: ${gutterHalfWidth}px;
        }
      `;
    } else {
      out += `
        #col${i} {
          margin-left: ${columnWidthPadded(i - 1, width) + gutterHalfWidth}px;
        }
      `;
    }
  }
  return out;
};

/* eslint-disable react/no-danger */

const UserLayoutStyle = ({ width }) => (
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html: `

/* layout */
.App.container.logged-in.collapsed .nav-bar {
  width: ${columnWidth(1, width) + gutterHalfWidth}px !important;
}
.App.container.logged-in.collapsed .content {
  width: ${columnWidth(10, width)}px !important;
  margin-left: ${gutterHalfWidth}px !important;
}
.App.container.logged-in.pulled-out .nav-bar {
  width: ${columnWidth(2, width) + gutterHalfWidth}px !important;
}
.App.container.logged-in.pulled-out .content {
  width: ${columnWidth(10, width)}px;
  margin-left: ${columnWidthPadded(1, width) + gutterHalfWidth}px !important;
}
.App.container.logged-in {
  margin-left: ${columnWidthPadded(1, width)}px !important;
}

/* buttons */
.App.container.logged-in .btn {
  width: ${columnWidth(2, width)}px !important;
}

/* forms */
.App.container.logged-in form .form-row,
.App.container.logged-in form input[type="text"],
.App.container.logged-in form input[type="password"],
.App.container.logged-in form select {
  width: ${columnWidth(4, width)}px !important;
}

/* dropdowns and misc */
table td .dropdown .dropdown-content {
  width: ${columnWidth(2, width)}px !important;
}
.active-table .search-bar {
  width: ${columnWidth(3, width)}px !important;
}

/* multi-column layouts */
.accordion .accordion-section .col-xs-6,
.org-info .col-xs-3,
.org-info .col-xs-4,
.user-info .col-xs-3,
.user-info .col-xs-4,
.user-information .col-xs-4 {
  width: ${columnWidth(3, width)}px !important;
  margin-right: ${gutterWidth}px;
}
.container-information .col-xs-3 {
  width: ${columnWidth(2, width)}px !important;
}

${printDebug(width)}
  `,
    }}
  />
);

UserLayoutStyle.propTypes = {
  width: PropTypes.number.isRequired,
};

/* eslint-enable react/no-danger */

export default UserLayoutStyle;
