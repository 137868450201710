import React from 'react';
import PropTypes from 'prop-types';
import OrgTable from './OrgTable';

const ArchivedOrgList = ({ fetchOrgs, onToggle }) => (
  <div className="org-list archived">
    <h1>Archived Organizations</h1>
    <OrgTable fetchOrgs={fetchOrgs} onToggle={onToggle} archived />
  </div>
);

ArchivedOrgList.propTypes = {
  fetchOrgs: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ArchivedOrgList;
