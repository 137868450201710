import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActiveTable from '../../components/active-table/ActiveTable';

const UserTable = ({
  fetchUsers, onToggle, onReset, archived,
}) => (
  <ActiveTable
    fetchData={fetchUsers}
    headings={[
      {
        key: 'firstName',
        label: 'Name',
        sortable: true,
        link: row => `/users/${row.id}`,
      },
      { key: 'commonName', label: 'Organization', sortable: true },
      { key: 'title', label: 'Title', sortable: false },
      { key: 'email', label: 'Email', sortable: false },
      { key: 'dropdown', label: '', sortable: false },
    ]}
    perPage={10}
    defaultSortField="firstName"
    searchPlaceholder="Search for Name"
    getId={row => row.id}
    buildCells={data => data.map(user => [
      `${user.firstName} ${user.lastName}`,
      user.organizationCommonName,
      user.role,
      user.email,
      <div className="dropdown">
        <div className="dropdown-content">
          <div className="dropdown-option">
            <Link to={`/users/${user.id}`}>View Information</Link>
          </div>
          <div className="dropdown-option">
            <Link to={`/users/${user.id}/edit`}>Edit User</Link>
          </div>
          <div className="dropdown-option">
            <button type="button" onClick={() => onReset(user.id)}>
                Reset Password
            </button>
          </div>
          <div className="dropdown-option">
            <button type="button" onClick={() => onToggle(user.id)}>
              {archived ? 'Restore User' : 'Archive User'}
            </button>
          </div>
        </div>
      </div>,
    ])
    }
  />
);

UserTable.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  archived: PropTypes.bool.isRequired,
};

export default UserTable;
