import {
  CONTAINER_LIST_FETCH_FINISHED,
  CONTAINER_LIST_FETCH_FAILED,
} from '../actionTypes';

export const getDefaultState = () => ({
  containers: null,
  isLoading: true,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONTAINER_LIST_FETCH_FINISHED:
      return {
        ...state,
        containers: action.containers,
        isLoading: false,
        error: null,
      };

    case CONTAINER_LIST_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case 'LOGOUT':
      return getDefaultState();

    default:
      return state;
  }
};

export default reducer;
