import React from 'react';
import PropTypes from 'prop-types';
import { map, uniq } from 'lodash';
import Accordion from '../../components/accordion/Accordion';
import DateTime from '../../components/DateTime';
import can from '../../utilities/can';
import buildUrl from '../../utilities/buildUrl';
import { TOOLTIP_READ } from '../../permissions';
import { AT_TCEF } from '../../constants';

// Helper function for displaying booleans
const checkBool = (input) => {
  if (typeof input === 'boolean') {
    if (input) {
      return 'Yes';
    }
    return 'No';
  }
  return input;
};

const buildData = (statuses, container, location, user) => {
  const currentLocation = location;
  // const statusKeys = Object.keys(statuses);
  return Object.keys(statuses)
    .reverse()
    .filter(
      statusKey => (statuses[statusKey].groupLocation || statuses[statusKey].location) === currentLocation,
    )
    // .filter(statusKey => statusKeys.indexOf(statusKey) <= statusKeys.indexOf(container.status))
    // .filter(statusKey => {
    //   if(currentLocation === "At TCEF") {
    //     return true;
    //   } else {
    //     return statusKeys.indexOf(statusKey) <= statusKeys.indexOf(container.status) // only display past-present statuses
    //   }  
    // })
    .map(statusKey => ({
    key: statusKey, 
    ...statuses[statusKey]
  })).reduce(
      (out, status) => ({
        ...out,
        [status.groupLabel || status.label]: Object.keys(status.fields)
          .filter((field) => {
            const fieldInfo = status.fields[field];
            const { permissions } = fieldInfo;
            if (permissions && permissions.read && !can(permissions.read, user)) {
              return false;
            }
            if (fieldInfo.display === false) {
              return false;
            }
            return true;
          })
          .reduce(
            (statusOut, fieldKey) => ({
              ...statusOut,
              [status.fields[fieldKey].label]: (() => {
                switch (status.fields[fieldKey].type) {
                  case 'datetime':
                  case 'datetimeOptional':
                  case 'datetimeHour':
                  case 'date': 
                    return <DateTime 
                              input={container[fieldKey]} 
                              isEdited={can(TOOLTIP_READ, user) && container[fieldKey + "IsEdited"]} 
                              editor={container[fieldKey + "EditedByUser"]}
                              editedAtTime={container[fieldKey + "EditedAtTime"]}
                            />;
                  case 'bool':
                    return (container[fieldKey + "IsEdited"] && can(TOOLTIP_READ, user)) ? 
                          <div className="edited-value">{checkBool(container[fieldKey])}
                            <span className="tooltiptext">
                              Last edited by user with id {container[fieldKey + "EditedByUser"] || "N/A"} on {container[fieldKey + "EditedAtTime"] || "N/A"}
                            </span>
                          </div> : checkBool(container[fieldKey]);
                  default:
                    return (container[fieldKey] && container[fieldKey + "IsEdited"] && can(TOOLTIP_READ, user)) ? 
                    <div className="edited-value">{container[fieldKey] || '-'}
                      <span className="tooltiptext">
                        Last edited by user with id {container[fieldKey + "EditedByUser"] || "N/A"} on {container[fieldKey + "EditedAtTime"] || "N/A"}
                      </span>
                    </div> : container[fieldKey] || '-';
                    //return container[fieldKey] || '-';
                }
              })(),
            }),
            out[status.groupLabel || status.label] || {},
          ),
      }),
      {},
    );
};

const labelStatusKeyMapping = (statuses, container, user) => {
  const statusKeys = Object.keys(statuses);
  let mapping = {};
  statusKeys.forEach((statusKey) => {
    if(can('CONTAINERS_WRITE_ORG_CBSA', user) ||
      (statuses[statusKey].permissions && statuses[statusKey].permissions.write && can(statuses[statusKey].permissions.write, user))) {
      if (statuses[statusKey]['conditionalOn']) { // status has conditional edit requirements
        let conditionalOn = statuses[statusKey]['conditionalOn'];
        if (container[conditionalOn['field']] !== conditionalOn['value']) {
          return;
        }
      }
      mapping[statuses[statusKey].label] = buildUrl('container-inventory', container.id, 'status', statusKey, 'edit')
    } else {
      mapping[statuses[statusKey].label] = ""
    }
    
  });
  return mapping;
}

const displayEditButton = (location, user) => {
  if(location === AT_TCEF && (can('CONTAINERS_WRITE_ORG_CBSA', user) || can('CONTAINERS_WRITE_*', user))) {
      return true;
  }
  return false;
}

const ContainerViewAccordion = ({
  container, statuses, location, user,
}) => {
  const locations = uniq(map(statuses, status => status.location));
  const currentLocation = location || statuses[container.status].location;
  const currentLocationKey = locations.indexOf(
    statuses[container.status].groupLocation || statuses[container.status].location,
  );
  const disabledGroups = locations.filter(key => locations.indexOf(key) > currentLocationKey);
  const data = buildData(statuses, container, currentLocation, user);
  const enableEdit = displayEditButton(currentLocation, user)
  const labelStatusURLMap = labelStatusKeyMapping(statuses, container, user) || {};
  return <Accordion key={Object.keys(data)} data={data} disabledGroups={disabledGroups} enableEdit={enableEdit} labelStatusURLMap={labelStatusURLMap}/>;
};

ContainerViewAccordion.defaultProps = {
  location: null,
};

ContainerViewAccordion.propTypes = {
  container: PropTypes.shape({}).isRequired,
  statuses: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  location: PropTypes.string,
};

export default ContainerViewAccordion;
