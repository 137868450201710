import React from 'react';
import { connect } from 'react-redux';
import { notificationFetch, notificationClick } from '../../actions/notifications';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  notification: state.notifications.notification,
  notifications: state.notifications.notifications,
  isLoading: state.notifications.isLoading,
  error: state.notifications.error,
});

const mapDispatchToProps = dispatch => ({
  fetchNotification: id => dispatch(notificationFetch(id)),
  onOpen: notification => dispatch(notificationClick(notification)),
});

class NotificationLinkContainer extends React.Component {
  componentDidMount() {
    const { id, fetchNotification } = this.props;
    fetchNotification(id);
  }

  render() {
    const {
      id, notification, notifications, error, onOpen,
    } = this.props;

    const eitherNotification = notification || (notifications && notifications.find(n => n.id === +id));
    if (eitherNotification) {
      onOpen(eitherNotification);
    }

    return (
      <Screen name="notifications" isLoading error={error}>
        ...
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationLinkContainer);
