import React from 'react';
import GuestLayout from './GuestLayout';
import UserLayout from './UserLayout';

const AutoLayout = (props) => {
  const { user, children } = props;
  const Component = user ? UserLayout : GuestLayout;
  return (
    <Component {...props}>
      {children}
    </Component>
  );
};

export default AutoLayout;
