import axios from 'axios';
import Api from './api';
import Storage from './Storage';
import createErrorReporter from './errorReporter';

export default () => {
  const storage = new Storage(localStorage);
  const api = new Api(axios.create({
    baseURL: '/api',
  }));
  const errorReporter = createErrorReporter(true, true);

  return {
    api,
    storage,
    errorReporter,
  };
};
