import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextInput extends Component {
  static defaultProps = {
    value: '',
    placeholder: '',
    fieldSuffix: '',
    valid: true,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { id, value } }) {
    const { onChange } = this.props;
    onChange(id, value);
  }

  render() {
    const {
      id, value, placeholder, fieldSuffix, valid, onBlur,
    } = this.props;
    const validClass = valid ? '' : 'invalid';
    return (
      <div className="text-input">
        <input
          id={id}
          value={value}
          placeholder={placeholder}
          className={validClass}
          onChange={this.handleChange}
          onBlur={onBlur}
          type="text"
        />
        {fieldSuffix !== '' ? <span className="field-suffix">{fieldSuffix}</span> : null}
      </div>
    );
  }
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

TextInput.displayName = 'TextInput';

export default TextInput;
