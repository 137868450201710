import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DateTimeRelative from '../../components/DateTimeRelative';
import { notificationPropType } from '../../propTypes';

const getUrl = notification => `/notifications/link/${notification.id}`;

const NotificationRow = ({
  notification, onRead, onUnread, onDismiss
}) => (
  <li key={notification.id} className={notification.unread ? 'unread' : 'read'}>
    <button type="button" title="Dismiss Notification" className="dismiss" onClick={onDismiss}>
      x
    </button>
    {notification.unread ? (
      <button type="button" title="Mark as Read" className="mark read" onClick={onRead}>
        Mark as Read
      </button>
    ) : (
      <button type="button" title="Mark as Unread" className="mark unread" onClick={onUnread}>
        Mark as Unread
      </button>
    )}
    <h3><Link to={getUrl(notification)}>{notification.title}</Link></h3>
    <p>{notification.message}</p>

    {notification.data.actionRequired && (
      <h4 className="action-required">Action Required</h4>
    )}

    <DateTimeRelative input={notification.dateCreated} />
  </li>
);

NotificationRow.propTypes = {
  notification: notificationPropType.isRequired,
  onRead: PropTypes.func.isRequired,
  onUnread: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationRow;
