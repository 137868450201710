/**
 * Session persistence middleware
 *
 * v1: apply writes
 * TODO v2: on boot, read, fire action to load session
 */
export default ({ storage }) => ({ getState }) => next => (action) => {
  const sessionBefore = getState().auth.session;
  next(action);
  const sessionAfter = getState().auth.session;

  if (sessionAfter !== sessionBefore) {
    storage.set('session', sessionAfter);
  }
};
