import { push, go } from 'connected-react-router';
import {
  APP_INIT,
  APP_SHUTDOWN,
  LOGIN_FINISHED,
  LOGIN_FAILED,
  LOGOUT,
  SESSION_START,
  SESSION_LOAD_START,
  SESSION_LOAD_FINISHED,
  SESSION_LOAD_FAILED,
  SESSION_REFRESH_FINISHED,
  SESSION_REFRESH_FAILED,
  USER_UPDATED,
} from './actionTypes';

import { ApiValidationError } from './services/api';

const doAppInit = () => ({
  type: APP_INIT,
});

export const appShutdown = () => ({
  type: APP_SHUTDOWN,
});

const sessionStart = () => ({
  type: SESSION_START,
});

const sessionLoadStart = session => ({
  type: SESSION_LOAD_START,
  session,
});

const sessionLoadFinished = (account, statuses) => ({
  type: SESSION_LOAD_FINISHED,
  account,
  statuses,
});

const sessionLoadFailed = error => ({
  type: SESSION_LOAD_FAILED,
  error,
});

export const loadSession = session => async (dispatch, getState, { api }) => {
  if (!session) {
    return false;
  }

  dispatch(sessionLoadStart(session));
  try {
    const [account, statuses] = await Promise.all([api.joinSession(session), api.getStatuses()]);
    dispatch(sessionLoadFinished(account, statuses));
    return true;
  } catch (e) {
    if (e.response && e.response.status === 401) {
      dispatch(sessionLoadFailed());
    } else {
      dispatch(sessionLoadFailed(e));
    }

    return false;
  }
};

export const sessionRefreshFinished = session => ({
  type: SESSION_REFRESH_FINISHED,
  session,
});

export const sessionRefreshFailed = error => ({
  type: SESSION_REFRESH_FAILED,
  error,
});

export const appInit = () => async (dispatch, getState, { storage }) => {
  dispatch(doAppInit());
  const session = storage.get('session');
  if (!session || !(await dispatch(loadSession(session)))) {
    dispatch(sessionStart());
  }
};

const loginFinished = (session, account, statuses) => ({
  type: LOGIN_FINISHED,
  account,
  session,
  statuses,
});

const loginFailed = (error, expected = false) => ({
  type: LOGIN_FAILED,
  error,
  expected,
});

export const loginStart = (email, password) => async (dispatch, getState, { api }) => {
  try {
    const session = await api.login(email, password);
    const [user, statuses] = await Promise.all([api.getUser(), api.getStatuses()]);
    dispatch(loginFinished(session, user, statuses));
    dispatch(push('/'));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(loginFailed(e));
  }
};

export const logout = () => async (dispatch, getState, { api }) => {
  api.logout();
  dispatch({ type: LOGOUT });
  dispatch(push('/', { message: 'Log-out successful.' }));
};

export const consumeFlashMessage = () => async (dispatch, getState, { browserHistory }) => {
  browserHistory.replace({
    ...getState().router.location,
    state: {},
  });
};

const userUpdated = user => ({
  type: USER_UPDATED,
  account: user,
});

export const changePassword = (...args) => async (dispatch, getState, { api }) => {
  try {
    const user = getState().auth.account;
    await api.changePassword(...args);

    dispatch(userUpdated(await api.getUser()));
    // eslint-disable-next-line no-alert
    alert('Password successfully changed.');

    if (user.isTemporaryPassword) {
      dispatch(push('/'));
    } else {
      dispatch(go('/settings/change-password'));
    }
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(loginFailed(e));
  }
};
