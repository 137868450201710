import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AccordionHeading = ({ title, onSelect, disabled, enableEdit, toURL }) => (
  <div className="accordion-heading">
    <h3>
      <button type="button" disabled={disabled} onClick={() => onSelect()}>{title}</button>
      {enableEdit && toURL && 
      <Link to={toURL}>
        <button type="button" disabled={disabled}>
          <img style={{paddingLeft: "10px"}} src="/images/edit.png" alt="Edit status"></img>
        </button>
      </Link>}
    </h3>
  </div>
);

AccordionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AccordionHeading;
