// can manage all users (port admin, app operator)
export const USERS_MANAGE_ALL = 'USERS_MANAGE_ALL';

// can manage users within their organization (GCT admin)
export const USERS_MANAGE_ORG = 'USERS_MANAGE_ORG';

// can manage organizations (port admin, app operator)
export const ORGS_MANAGE_ALL = 'ORGS_MANAGE_ALL';

// can read ALL containers in the system (internal / app operator)
export const CONTAINERS_READ = 'CONTAINERS_READ';

// can read containers their organization is involved with (VFPA)
export const CONTAINERS_READ_ORG = 'CONTAINERS_READ_ORG';

// can write ALL containers in the system (internal / app operator)
export const CONTAINERS_WRITE = 'CONTAINERS_WRITE';

// can write any status changes scoped to their organization involvement (VFPA admin?)
export const CONTAINERS_WRITE_ORG = 'CONTAINERS_WRITE_ORG';

// can write port status changes scoped to org (integration / api user)
export const CONTAINERS_WRITE_ORG_PORT = 'CONTAINERS_WRITE_ORG_PORT';

// can write terminal status changes scoped to org (GCT)
export const CONTAINERS_WRITE_ORG_TERMINAL = 'CONTAINERS_WRITE_ORG_TERMINAL';

// can write cef status changes scoped to org (CEF)
export const CONTAINERS_WRITE_ORG_CEF = 'CONTAINERS_WRITE_ORG_CEF';

// can read cef status changes scoped to org (CEF)
export const CONTAINERS_READ_ORG_CEF = 'CONTAINERS_READ_ORG_CEF';

// can write cbsa status changed scope to org (CBSA)
export const CONTAINERS_WRITE_ORG_CBSA = 'CONTAINERS_WRITE_ORG_CBSA';

// can see analytics info (to be expanded later)
export const ANALYTICS_READ = 'ANALYTICS_READ';

// can see shipping information? @see VFPA-197
export const CONTAINERS_READ_LOCATION = 'CONTAINERS_READ_LOCATION';

// can see tool tip for edits information?
export const TOOLTIP_READ = 'TOOLTIP_READ';

export const allPermissions = [
  USERS_MANAGE_ALL,
  USERS_MANAGE_ORG,
  ORGS_MANAGE_ALL,
  CONTAINERS_READ,
  CONTAINERS_READ_ORG,
  CONTAINERS_WRITE,
  CONTAINERS_WRITE_ORG,
  CONTAINERS_WRITE_ORG_PORT,
  CONTAINERS_WRITE_ORG_TERMINAL,
  CONTAINERS_WRITE_ORG_CEF,
  CONTAINERS_READ_ORG_CEF,
  CONTAINERS_WRITE_ORG_CBSA,
  CONTAINERS_READ_LOCATION,
  ANALYTICS_READ,
  TOOLTIP_READ
];

export default {
  superadmin: {
    label: 'Super Administrator (internal)',
    roles: {
      superadmin: {
        label: 'Super Administrator',
        permissions: allPermissions,
        active: true,
      },
    },
  },
  port: {
    label: 'Port',
    roles: {
      'port-admin': {
        label: 'Port Admin',
        permissions: [
          USERS_MANAGE_ALL,
          USERS_MANAGE_ORG,
          ORGS_MANAGE_ALL,
          CONTAINERS_READ_ORG,
          CONTAINERS_READ_ORG_CEF,
          ANALYTICS_READ,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
    },
  },
  'shipping-line': {
    label: 'Shipping Line',
    roles: {
      'shipping-line-admin': {
        label: 'Shipping Line Administrator',
        permissions: [USERS_MANAGE_ORG, CONTAINERS_READ_ORG],
        active: false,
      },
      'shipping-line-read': {
        label: 'Shipping Line (read)',
        permissions: [CONTAINERS_READ_ORG],
        active: true,
      },
    },
  },
  terminal: {
    label: 'Terminal',
    roles: {
      'terminal-admin': {
        label: 'Terminal Administrator',
        permissions: [
          USERS_MANAGE_ORG,
          CONTAINERS_READ_ORG,
          CONTAINERS_WRITE_ORG_TERMINAL,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: false,
      },
      'terminal-read': {
        label: 'Terminal (read)',
        permissions: [CONTAINERS_READ_ORG, CONTAINERS_READ_LOCATION, TOOLTIP_READ],
        active: true,
      },
      'terminal-write': {
        label: 'Terminal (write)',
        permissions: [CONTAINERS_READ_ORG, CONTAINERS_WRITE_ORG_TERMINAL, CONTAINERS_READ_LOCATION, TOOLTIP_READ],
        active: false,
      },
    },
  },
  cef: {
    label: 'CEF',
    roles: {
      'cef-admin': {
        label: 'CEF Administrator',
        permissions: [
          USERS_MANAGE_ORG,
          CONTAINERS_READ_ORG,
          CONTAINERS_READ_LOCATION,
          CONTAINERS_WRITE_ORG_CEF,
          CONTAINERS_READ_ORG_CEF,
          TOOLTIP_READ,
        ],
        active: false,
      },
      'cef-read': {
        label: 'CEF (read)',
        permissions: [CONTAINERS_READ_ORG, CONTAINERS_READ_ORG_CEF, CONTAINERS_READ_LOCATION, TOOLTIP_READ],
        active: true,
      },
      'cef-write': {
        label: 'CEF (write)',
        permissions: [
          CONTAINERS_READ_ORG,
          CONTAINERS_WRITE_ORG_CEF,
          CONTAINERS_READ_ORG_CEF,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
      'cef-integration': {
        label: 'CEF (integration)',
        permissions: [
          CONTAINERS_READ_ORG,
          CONTAINERS_WRITE_ORG_CEF,
          CONTAINERS_READ_ORG_CEF,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
    },
  },
  cbsa: {
    label: 'CBSA',
    roles: {
      'cbsa-admin': {
        label: 'CBSA Administrator',
        permissions: [
          USERS_MANAGE_ORG,
          CONTAINERS_READ,
          CONTAINERS_READ_ORG,
          CONTAINERS_READ_ORG_CEF,
          CONTAINERS_WRITE_ORG_CBSA,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
      'cbsa-write': {
        label: 'CBSA (write)',
        permissions: [
          CONTAINERS_READ,
          CONTAINERS_READ_ORG,
          CONTAINERS_WRITE_ORG_CEF,
          CONTAINERS_READ_ORG_CEF,
          CONTAINERS_WRITE_ORG_CBSA,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
      'cbsa-read': {
        label: 'CBSA (read)',
        permissions: [
          CONTAINERS_READ,
          CONTAINERS_READ_ORG,
          CONTAINERS_READ_ORG_CEF,
          CONTAINERS_READ_LOCATION,
          TOOLTIP_READ,
        ],
        active: true,
      },
    },
  },
};
