import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class SelectInput extends Component {
  static defaultProps = {
    value: '',
    placeholder: '',
    fieldSuffix: '',
    valid: true,
    options: [],
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(selectedOption) {
    if (!selectedOption) {
      return;
    }
    const { value } = selectedOption;
    const { id, onChange, onBlur } = this.props;

    await onChange(id, value);
    onBlur();
  }

  render() {
    const {
      id, value, placeholder, fieldSuffix, valid, options,
    } = this.props;
    const validClass = valid ? '' : 'invalid';
    return (
      <div className="select-input">
        <Select
          id={id}
          value={options.find(option => option.value === value)}
          placeholder={placeholder}
          className={validClass}
          onChange={this.handleChange}
          options={options}
          autoBlur
          clearable={false}
          isSearchable={false}
        />
        {fieldSuffix !== '' ? <span className="field-suffix">{fieldSuffix}</span> : null}
      </div>
    );
  }
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
      className: PropTypes.string,
    }),
  ),
};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
