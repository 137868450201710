import { go } from 'connected-react-router';
import {
  ORG_ARCHIVE_FINISHED,
  ORG_ARCHIVE_FAILED,
  ORG_RESTORE_FINISHED,
  ORG_RESTORE_FAILED,
} from '../actionTypes';

const orgArchiveFinished = () => ({
  type: ORG_ARCHIVE_FINISHED,
});

const orgArchiveFailed = error => ({
  type: ORG_ARCHIVE_FAILED,
  error,
});

const orgRestoreFinished = () => ({
  type: ORG_RESTORE_FINISHED,
});

const orgRestoreFailed = error => ({
  type: ORG_RESTORE_FAILED,
  error,
});

export const orgFetchAll = () => async (dispatch, getState, { api }) => api.getAllOrgs();

export const orgListFetch = criteria => async (dispatch, getState, { api }) => api.searchOrgs(criteria);

export const orgArchive = id => async (dispatch, getState, { api }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const archive = confirm('Archive organization?');
    if (archive) {
      await api.archiveOrg(id);
      alert('Organization archived!');
      dispatch(orgArchiveFinished());
      dispatch(go('/organizations'));
    }
  } catch (e) {
    dispatch(orgArchiveFailed(e));
  }
};

export const orgRestore = id => async (dispatch, getState, { api }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const restore = confirm('Restore organization?');
    if (restore) {
      await api.restoreOrg(id);
      alert('Organization restored!');
      dispatch(orgRestoreFinished());
      dispatch(go('/organizations/archived'));
    }
  } catch (e) {
    dispatch(orgRestoreFailed(e));
  }
};
