import React from 'react';
import { connect } from 'react-redux';
import UserAdd from './UserAdd';
import Screen from '../../components/Screen';
import { userAdd } from '../../actions/userAdd';
import { orgFetchAll } from '../../actions/orgList';

const mapStateToProps = state => ({
  orgs: state.orgList.orgs,
  isLoading: state.userAdd.isLoading,
  error: state.userAdd.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrgs: () => dispatch(orgFetchAll()),
  onSubmit: data => dispatch(userAdd(data)),
});

class UserAddContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: null,
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.getAllOrgs();
  }

  async getAllOrgs() {
    try {
      const { fetchOrgs } = this.props;
      const orgs = await fetchOrgs();
      this.setState({
        orgs,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error,
      });
    }
  }

  handleSubmit = async (data) => {
    const { onSubmit } = this.props;
    try {
      await onSubmit(data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { orgs, isLoading, error: stateError } = this.state;
    const { error: propError } = this.props;
    return (
      <Screen name="user-add" isLoading={isLoading} error={propError}>
        <UserAdd
          {...this.props}
          orgs={orgs || []}
          onSubmit={this.handleSubmit}
          error={stateError}
        />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAddContainer);
