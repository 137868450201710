import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import AppContainer from './AppContainer';
import createServices from './services';
import createStore from './store';
import { appInit } from './actions';
import './polyfills';

const config = {};
const services = createServices(config);
window.services = services;
const { store, history } = createStore(services, config);

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContainer />
    </ConnectedRouter>
  </Provider>
);

store.dispatch(appInit());

ReactDOM.render(app, document.getElementById('root'));
