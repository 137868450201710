import React from 'react';
import PropTypes from 'prop-types';

class Screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const {
      name, helpText, isLoading, children, error: propError, debug,
    } = this.props;
    const { error: stateError, errorInfo } = this.state;

    const error = stateError || propError;

    if (error) {
      const errorMessage = `Could not load ${name}...`;
      return (
        <div className="error-screen">
          <h2>{errorMessage}</h2>
          <p>{helpText}</p>
          {errorInfo && debug && <pre>{errorInfo.componentStack}</pre>}
          {error && debug && <pre>{error.stack}</pre>}
        </div>
      );
    }

    if (isLoading) {
      const loadingMessage = `Loading ${name}...`;
      return (
        <div className="loading">
          <h1>{loadingMessage}</h1>
        </div>
      );
    }

    return children;
  }
}

Screen.defaultProps = {
  helpText: 'Refresh to try again.',
  error: null,
  debug: true,
};

Screen.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  helpText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  debug: PropTypes.bool,
};

export default Screen;
