import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileItem from '../../components/ProfileItem';
import { userPropType } from '../../propTypes';

const UserProfile = ({ user }) => (
  <div className="user-information">
    <h2>User Profile</h2>
    <Row>
      <Col xs={3}>
        <ProfileItem label="First Name" value={user.firstName} />
        <ProfileItem label="Email" value={user.email} />
      </Col>
      <Col xs={3}>
        <ProfileItem label="Last Name" value={user.lastName} />
        <ProfileItem label="Organization" value={user.organizationCommonName} />
      </Col>
    </Row>
  </div>
);

UserProfile.propTypes = {
  user: userPropType.isRequired,
};

export default UserProfile;
