import React from 'react';
import PropTypes from 'prop-types';
import LocationStatusBar from '../../components/LocationStatusBar';
import { containerPropType, statusesPropType } from '../../propTypes';
import { getNextStatusLabel } from '../../utilities/statuses';

import './ContainerView.scss';

const ContainerLocation = ({
  container, statuses, location, showNextStatus,
}) => (
  <div>
    <h2>Container Location</h2>
    <div className="location-status">
      {showNextStatus ? (
        <h3>
          Next Status:
          {' '}
          <span>{getNextStatusLabel(container, statuses, container.status)}</span>
        </h3>
      ) : (
        <h3>
          <span>
            Status:&nbsp;
            {statuses[container.status].label}
          </span>
        </h3>
      )}
    </div>
    <div className="location-status-bar">
      <LocationStatusBar statuses={statuses} container={container} location={location} />
    </div>
  </div>
);

ContainerLocation.defaultProps = {
  showNextStatus: false,
  location: null,
};

ContainerLocation.propTypes = {
  container: containerPropType.isRequired,
  statuses: statusesPropType.isRequired,
  location: PropTypes.string,
  showNextStatus: PropTypes.bool,
};

export default ContainerLocation;
