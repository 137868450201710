import React from 'react';
import { connect } from 'react-redux';
import Notifications from './Notifications';
import {
  notificationListFetch,
  notificationsRead,
  notificationRead,
  notificationUnread,
  notificationDismiss,
  notificationClick,
} from '../../actions/notifications';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  isLoading: state.notifications.isLoading,
  error: state.notifications.error,
});

const mapDispatchToProps = dispatch => ({
  fetchNotifications: () => dispatch(notificationListFetch()),
  onNotificationClick: id => dispatch(notificationClick(id)),
  onNotificationRead: id => dispatch(notificationRead(id)),
  onNotificationUnread: id => dispatch(notificationUnread(id)),
  onNotificationDismiss: id => dispatch(notificationDismiss(id)),
  onNotificationsRead: id => dispatch(notificationsRead(id)),
});

class NotificationsContainer extends React.Component {
  componentDidMount() {
    const { fetchNotifications } = this.props;
    fetchNotifications();
  }

  render() {
    const { notifications, isLoading, error } = this.props;
    return (
      <Screen name="notifications" isLoading={isLoading} error={error}>
        <Notifications notifications={notifications || []} {...this.props} />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsContainer);
