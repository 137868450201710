import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import TextInput from '../../components/form/elements/TextInput';
import SelectInput from '../../components/form/elements/SelectInput';

export default ({
  org = {}, terminals, cefs, onSubmit, ...props
}) => {
  const forms = {
    terminal: [
      {
        id: 'remoteId',
        label: 'Organization ID',
        inputComponent: TextInput,
        inputOptions: { placeholder: 'The ID from navis, etc.' },
        required: true,
        validation: joi.string(),
      },
    ],
    port: [
      {
        id: 'terminalId',
        label: 'Terminal',
        inputComponent: SelectInput,
        inputOptions: {
          options: terminals.map(terminal => ({
            value: terminal.id,
            label: terminal.name,
          })),
        },
        required: true,
        validation: joi.number(),
      },
      {
        id: 'cefId',
        label: 'CEF',
        inputComponent: SelectInput,
        inputOptions: {
          options: cefs.map(cef => ({
            value: cef.id,
            label: cef.name,
          })),
        },
        required: true,
        validation: joi.number(),
      },
    ],
    'shipping-line': [
      {
        id: 'remoteId',
        label: 'Organization ID',
        inputComponent: TextInput,
        inputOptions: { placeholder: 'The ID from navis, etc.' },
        required: true,
        validation: joi.string(),
      },
    ],
    cef: [
      {
        id: 'remoteId',
        label: 'Organization ID',
        inputComponent: TextInput,
        inputOptions: { placeholder: 'The ID from navis, etc.' },
        required: true,
        validation: joi.string(),
      },
    ],
  };

  return createForm({
    fields: forms[org.roleType] || [],
    layoutOptions: {
      submitText: 'Save',
    },
    onSubmit,
    ...props,
  });
};
