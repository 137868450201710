import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import can from '../../utilities/can';
import UserLayoutStyle from './UserLayoutStyle';

import './UserLayout.scss';

const links = [
  {
    key: 'containers',
    label: 'Container List',
    url: '/container-inventory',
    permission: 'CONTAINERS_*',
  },
  {
    key: 'container-history',
    label: 'Archive List',
    url: '/container-archive',
    permission: 'CONTAINERS_*',
  },
  {
    key: 'analytics',
    label: 'Data Export',
    url: '/analytics',
    permission: 'ANALYTICS_*',
  },
  {
    key: 'users',
    label: 'Users',
    url: '/users',
    permission: 'USERS_*',
  },
  {
    key: 'organizations',
    label: 'Organizations',
    url: '/organizations',
    permission: 'ORGS_*',
  },
  {
    key: 'notifications',
    label: 'Notifications',
    url: '/notifications',
  },
  {
    key: 'settings',
    label: 'Settings',
    url: '/settings',
  },
  {
    key: 'signout',
    label: 'Sign Out',
    url: '/logout',
  },
];

class UserLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarHover: true,
      width: 1440,
    };
    this.debouncedResize = debounce(this.handleResize, 150);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedResize);
    setTimeout(this.debouncedResize, 1000);
    this.debouncedResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  handleResize = () => {
    this.setState({
      width: Math.min(
        Math.max(1280, document.documentElement.clientWidth || document.body.clientWidth),
        1920,
      ),
    });
  };

  handleMouseEnter = () => {
    // this.setState({ sidebarHover: true });
  };

  handleMouseLeave = () => {
    // this.setState({ sidebarHover: false });
  };

  permissionCheck = (navItem) => {
    const { user } = this.props;
    return navItem.permission ? can(navItem.permission, user) : true;
  };

  render() {
    const { children, user } = this.props;
    const { width, sidebarHover } = this.state;

    const classes = [
      'App',
      'container',
      'logged-in',
      sidebarHover ? 'pulled-out' : 'collapsed',
      user.notificationsUnread ? 'notifications-unread' : '',
    ];

    return (
      <div className={classes.join(' ')}>
        <UserLayoutStyle width={width} />
        <div
          className="nav-bar"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className="nav-bar-inner">
            <Nav bsStyle="pills" activeKey={1} stacked pullLeft>
              {links.filter(this.permissionCheck).map(({ key, label, url }, i) => (
                <LinkContainer key={key} to={url}>
                  <NavItem className={key} eventKey={i}>
                    {label}
                  </NavItem>
                </LinkContainer>
              ))}
            </Nav>
          </div>
        </div>
        <div className="content">
          <div className="content-inner">
            {children}
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
    );
  }
}

UserLayout.propTypes = {
  user: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default UserLayout;
