import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import TextInput from '../../components/form/elements/TextInput';
import SelectInput from '../../components/form/elements/SelectInput';
import permissions from '../../permissions';

export default ({
  orgs, user = {}, onSubmit, ...props
}) => {
  const orgOptions = orgs
    .map(org => ({
      value: org.id,
      label: org.commonName,
      isActive: org.isActive,
    }))
    .filter(({ isActive }) => isActive);
  const roleOptions = Object.keys(permissions)
    .map(permission => [permission, Object.keys(permissions[permission].roles)])
    .reduce(
      (out, [permission, roleKeys]) => [...out, ...roleKeys.map(roleKey => [permission, roleKey])],
      [],
    )
    .map(([permission, roleKey]) => ({
      value: roleKey,
      label: permissions[permission].roles[roleKey].label,
      active: permissions[permission].roles[roleKey].active,
    }))
    .filter(({ active }) => active);

  return createForm({
    fields: [
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: user.email,
      },
      {
        id: 'firstName',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: user.firstName,
      },
      {
        id: 'lastName',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: user.lastName,
      },
      {
        id: 'organizationId',
        label: 'Organization',
        inputComponent: SelectInput,
        required: true,
        validation: joi.number().error(errors => errors.map(error => ({
          ...error,
          // they want the errors to be consistent, but this is actually the string error message
          message:
              error.type === 'number.base' ? '"Organization" is not allowed to be empty' : null,
        }))),
        inputOptions: { options: orgOptions },
        defaultValue: user.organizationId,
      },
      {
        id: 'role',
        label: 'Role',
        inputComponent: SelectInput,
        required: true,
        validation: joi.string(),
        inputOptions: { options: roleOptions },
        defaultValue: user.role,
      },
    ],
    layoutOptions: {
      submitText: 'Save',
    },
    onSubmit,
    ...props,
  });
};
