import { map, pickBy } from 'lodash';
import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import TextInput from '../../components/form/elements/TextInput';
import DateTimeInput from '../../components/form/elements/DateTimeInput';
import ToggleInput from '../../components/form/elements/ToggleInput';
import { getNextStatus } from '../../utilities/statuses';

const overrides = (field) => {
  if (field.type === 'datetime' || field.type === 'date') {
    if (field.optional) {
      return {
        validation: joi
          .date()
          .allow('')
          .label(field.label),
        inputComponent: DateTimeInput,
        inputOptions: {
          timezone: 'America/Vancouver',
        },
      };
    }
    return {
      inputComponent: DateTimeInput,
      validation: joi
        .date()
        .required()
        .label(field.label),
    };
  }
  if (field.type === 'datetimeHour') {
    return {
      validation: joi
        .date()
        .required()
        .label(field.label),
      inputComponent: DateTimeInput,
      inputOptions: {
        timeIntervals: 60,
      },
    };
  }
  if (field.type === 'string') {
    if (field.optional) {
      return {
        validation: joi
          .string()
          .allow('')
          .label(field.label),
        inputComponent: TextInput,
      };
    }

    return {
      validation: joi
        .string()
        .required()
        .label(field.label),
      inputComponent: TextInput,
    };
  }
  if (field.type === 'bool') {
    return {
      defaultValue: false,
      validation: joi
        .boolean()
        .required()
        .label(field.label),
      inputComponent: ToggleInput,
    };
  }
  throw new Error(`Unknown field type: ${field.type}`);
};

const applyForcedField = (field, container) => {
  if (field.setEqualTo) {
    return {
      defaultValue: container[field.setEqualTo],
      inputOptions: {
        disabled: true,
      },
    };
  }
  return {};
};

const _getNextStatus = (container, statuses, statusKey) => {
  if(statusKey) {
      return statuses[statusKey];
  } else {
    return getNextStatus(container, statuses, container.status);
  }
}

export default ({
  container, statuses, onSubmit, statusKey, ...props
}) => {
  const nextStatus = _getNextStatus(container, statuses, statusKey);
  if (typeof nextStatus === 'undefined') {
    throw new Error('Should not render form with invalid status');
  }
  const { fields } = nextStatus;

  return createForm({
    fields: map(
      pickBy(fields, field => field.hiddenFromUpdate !== true && field.display !== false),
      (field, key) => ({
        id: key,
        label: field.label,
        // temporary
        validation: joi
          .string()
          .allow('')
          .label(field.label),
        ...overrides(field),
        ...applyForcedField(field, container),
      }),
    ),
    layoutOptions: {
      submitText: 'Save',
    },
    onSubmit,
    ...props,
  });
};
