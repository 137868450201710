import React from 'react';
import PropTypes from 'prop-types';

const buildClass = val => (val ? 'toggle on' : 'toggle off');
const buildTitle = val => (val ? 'Click to turn off' : 'Click to turn on');

const ToggleButton = ({ value, onChange }) => (
  <div>
    <button title={buildTitle(value)} className={buildClass(value)} type="button" onClick={() => onChange(!value)}>
      {value ? 'On' : 'Off'}
    </button>
  </div>
);

ToggleButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default ToggleButton;
