import { push } from 'connected-react-router';
import { ORG_ADD_FINISHED, ORG_ADD_FAILED } from '../actionTypes';
import { ApiValidationError } from '../services/api';

const orgAddFinished = org => ({
  type: ORG_ADD_FINISHED,
  org,
});

const orgAddFailed = error => ({
  type: ORG_ADD_FAILED,
  error,
});

export const orgAdd = data => async (dispatch, getState, { api }) => {
  try {
    const org = await api.createOrg(data);
    dispatch(orgAddFinished(org));

    if (org.configured) {
      dispatch(push('/organizations'));
    } else {
      dispatch(push(`/organizations/${org.id}/configure`));
    }
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(orgAddFailed(e));
  }
};
