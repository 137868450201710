import React from 'react';
import { connect } from 'react-redux';
import OrgConfigure from './OrgConfigure';
import Screen from '../../components/Screen';
import {
  orgInfoFetch, orgConfigure, orgTerminalsFetch, orgCefsFetch,
} from '../../actions/orgInfo';

const mapStateToProps = state => ({
  org: state.orgInfo.org,
  isLoading: state.orgInfo.isLoading,
  error: state.orgInfo.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrg: id => dispatch(orgInfoFetch(id)),
  fetchTerminals: () => dispatch(orgTerminalsFetch()),
  fetchCefs: () => dispatch(orgCefsFetch()),
  onSubmit: (id, data) => dispatch(orgConfigure(id, data)),
});

class OrgConfigureContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      terminals: null,
      terminalsLoading: true,
      cefs: null,
      cefsLoading: true,
    };
  }

  componentDidMount() {
    const { id, fetchOrg } = this.props;
    fetchOrg(id);
    this.fetchTerminals();
    this.fetchCefs();
  }

  fetchTerminals = async () => {
    const { fetchTerminals } = this.props;
    try {
      const terminals = await fetchTerminals();
      this.setState({
        terminals,
        terminalsLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        terminalsLoading: false,
      });
    }
  };

  fetchCefs = async () => {
    const { fetchCefs } = this.props;
    try {
      const cefs = await fetchCefs();
      this.setState({
        cefs,
        cefsLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        cefsLoading: false,
      });
    }
  };

  handleSubmit = async (data) => {
    const { id, onSubmit } = this.props;
    try {
      await onSubmit(id, data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { isLoading, error: propError } = this.props;
    const {
      error: stateError, terminals, cefs, terminalsLoading, cefsLoading,
    } = this.state;
    const loading = isLoading || terminalsLoading || cefsLoading;

    return (
      <Screen name="org-edit" isLoading={loading} error={propError}>
        <OrgConfigure
          {...this.props}
          onSubmit={this.handleSubmit}
          error={stateError}
          terminals={terminals}
          cefs={cefs}
        />
      </Screen>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgConfigureContainer);
