import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const getDays = (input, reference) => moment(reference).diff(input, 'days');
const getHours = (input, reference) => moment(reference).diff(input, 'hours') % 24;

const Duration = ({ input, reference }) => {
  const days = getDays(input, reference || new Date());
  const hours = getHours(input, reference || new Date());
  return <div className="duration">{`${days}D ${hours}H`}</div>;
};

Duration.defaultProps = {
  input: null,
  reference: null,
};

Duration.propTypes = {
  input: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  reference: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default Duration;
