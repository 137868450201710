import { push } from 'connected-react-router';
import {
  USER_INFO_FETCH_FINISHED,
  USER_INFO_FETCH_FAILED,
  USER_EDIT_FINISHED,
  USER_EDIT_FAILED,
} from '../actionTypes';
import { ApiValidationError } from '../services/api';

const userInfoFetchFinished = user => ({
  type: USER_INFO_FETCH_FINISHED,
  user,
});

const userInfoFetchFailed = error => ({
  type: USER_INFO_FETCH_FAILED,
  error,
});

const userEditFinished = user => ({
  type: USER_EDIT_FINISHED,
  user,
});

const userEditFailed = error => ({
  type: USER_EDIT_FAILED,
  error,
});

export const userInfoFetch = id => async (dispatch, getState, { api }) => {
  try {
    const user = await api.getUserInfo(id);
    dispatch(userInfoFetchFinished(user));
    return user;
  } catch (e) {
    dispatch(userInfoFetchFailed(e));
    return null;
  }
};

export const userEdit = (id, fields) => async (dispatch, getState, { api }) => {
  try {
    const user = await api.updateUser(id, fields);
    dispatch(userEditFinished(user));
    dispatch(push(`/users/${id}`));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(userEditFailed(e));
  }
};

const userChangeSettingFailed = (field, value, error) => ({
  type: 'USER_SETTINGS_CHANGE_FAILED',
  field,
  value,
  error,
});

const userChangeSettingFinished = (field, value) => ({
  type: 'USER_SETTINGS_CHANGE_FINISHED',
  field,
  value,
});

export const userSettingChange = (field, value) => async (dispatch, getState, { api }) => {
  try {
    await api.changeSetting(field, value);
    dispatch(userChangeSettingFinished(field, value));
  } catch (e) {
    dispatch(userChangeSettingFailed(field, value, e));
  }
};
