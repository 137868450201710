import React from 'react';
import { connect } from 'react-redux';
import UserInformation from './UserInformation';
import { userInfoFetch } from '../../actions/userInfo';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  user: state.userInfo.user,
  isLoading: state.userInfo.isLoading,
  error: state.userInfo.error,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(userInfoFetch(id)),
});

class UserInformationContainer extends React.Component {
  componentDidMount() {
    const { id, fetchUser } = this.props;
    fetchUser(id);
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Screen name="users-information" isLoading={isLoading}>
        <UserInformation {...this.props} />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInformationContainer);
