import React from 'react';
import PropTypes from 'prop-types';

import './GuestLayout.scss';

const GuestLayout = ({ children }) => <div className="App container guest">{children}</div>;

GuestLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default GuestLayout;
