import React from 'react';
import { connect } from 'react-redux';
import UserList from './UserList';
import { userListFetch, userPasswordReset, userArchive } from '../../actions/userList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  users: state.userList.users,
  isLoading: state.userList.isLoading,
  error: state.userList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: criteria => dispatch(
    userListFetch({
      ...criteria,
      archived: false,
    }),
  ),
  onReset: id => dispatch(userPasswordReset(id)),
  onToggle: id => dispatch(userArchive(id)),
});

const UserListContainer = props => (
  <Screen name="users" isLoading={false}>
    <UserList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserListContainer);
