import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserTable from './UserTable';

const UserList = ({ fetchUsers, onReset, onToggle }) => (
  <div className="user-list">
    <h1>Users</h1>
    <div className="actions">
      <ul className="nav">
        <li>
          <Link to="/users/archived" className="btn secondary">
            View Archived List
          </Link>
        </li>
        <li>
          <Link to="/users/add" className="btn btn-primary">
            Add User
          </Link>
        </li>
      </ul>
    </div>
    <UserTable fetchUsers={fetchUsers} onReset={onReset} onToggle={onToggle} archived={false} />
  </div>
);

UserList.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default UserList;
