import moment from 'moment-timezone';

export class ApiValidationError extends Error {}

export default class Api {
  constructor(client) {
    this.client = client;
    this.client.defaults.headers['x-user-timezone'] = moment.tz.guess();
  }

  /**
   * Joins a session and validates it
   *
   * @param {String} session token
   * @return {Promise<Object>}
   */
  async joinSession(session) {
    this.client.defaults.headers.authorization = session;
    return this.getUser();
  }

  /**
   * Gets a session for a given email/password
   *
   * @param {String} email
   * @param {String} password
   * @return {Promise<String>} - JWT
   */
  async login(email, password) {
    if (email === '' || password === '') {
      throw new ApiValidationError('Please ensure both Email and Password fields are filled in.');
    }
    try {
      const response = await this.client.post('/auth/login', { email, password });
      this.client.defaults.headers.authorization = response.data.token;
      return response.data.token;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        throw new ApiValidationError(e.response.data.message);
      }
      if (e.response && (e.response.status === 401 || e.response.status === 400)) {
        if (e.response.data.message.indexOf('expired') !== -1) {
          throw new ApiValidationError(e.response.data.message);
        }

        throw new ApiValidationError(
          'An incorrect combination of email and/or password has been entered. Please try again.',
        );
      }
      if (e.response && e.response.status === 403) {
        throw new ApiValidationError(
          'An incorrect combination of email and/or password has been entered at least 3 times. Please wait 30 minutes or reach out to an administrator to reset your password.',
        );
      }
      throw new ApiValidationError(
        'Sorry, we are unable to connect to the server right now. Please try again later.',
      );
    }
  }

  /**
   * Get a new access token while our session is still valid
   */
  async refreshSession() {
    try {
      const response = await this.client.post('/auth/refresh');
      this.client.defaults.headers.authorization = response.data.token;
      return response.data.token;
    } catch (e) {
      if (e.response && (e.response.status === 401 || e.response.status === 400)) {
        throw new ApiValidationError('Invalid credentials');
      }
      throw e;
    }
  }

  /**
   * Sends a password change to the server
   */
  async changePassword(currentPassword, newPassword, confirmPassword) {
    try {
      await this.client.put('/user/password', { currentPassword, newPassword, confirmPassword });
    } catch (e) {
      if (e.response && e.response.status === 403) {
        throw new ApiValidationError('Your current password is incorrect. Please try again.');
      }
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('empty') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        if (e.response.data.message.indexOf('complexity') !== -1) {
          throw new ApiValidationError(
            'Passwords must contain at least 6 characters, at least one uppercase letter and one symbol. Please try again.',
          );
        }
        if (e.response.data.message.indexOf('ref') !== -1) {
          throw new ApiValidationError('Your new passwords do not match. Please try again.');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  /**
   * Fetches info about the current user
   *
   * @return {Promise<Object>}
   */
  async getUser() {
    const { data } = await this.client.get('/user');
    return data;
  }

  /**
   * Fetches info about all users
   *
   * @param {Object} criteria - search criteria
   * @return {Promise<Object>}
   */
  async getUsers(criteria) {
    const params = {
      sortField: criteria.sort.field,
      sortOrder: criteria.sort.order,
      page: criteria.page,
      perPage: criteria.perPage,
    };

    if (criteria.filter) {
      params.filter = criteria.filter;
    }
    if (typeof criteria.archived === 'boolean') {
      params.archived = criteria.archived;
    }

    const { data, headers } = await this.client.get('/admin/users', { params });
    return {
      data,
      pages: parseInt(headers['x-total-pages'], 10),
    };
  }

  /**
   * Fetches info about the given user
   *
   * @param {String} id
   * @return {Promise<Object>}
   */
  async getUserInfo(id) {
    const { data } = await this.client.get(`/admin/users/${id}`);
    return data;
  }

  /**
   * @param {String} id
   * @param {Object} fields
   */
  async updateUser(id, fields) {
    try {
      const { data } = await this.client.put(`/admin/users/${id}`, fields);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  /**
   *
   * @param {Object} fields
   */
  async createUser(fields) {
    try {
      const { data } = await this.client.post('/admin/users', fields);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  /**
   *
   * @param {String} id
   */
  async archiveUser(id) {
    const { data } = await this.client.delete(`/admin/users/${id}`);
    return data;
  }

  /**
   *
   * @param {String} id
   */
  async restoreUser(id) {
    const { data } = await this.client.put(`/admin/users/${id}/restore`);
    return data;
  }

  /**
   *
   * @param {String} id
   */
  async resetUserPassword(id) {
    const { data } = await this.client.post(`/admin/users/${id}/password`);
    return data;
  }

  async getAllOrgs() {
    const params = {
      sortField: 'commonName',
      sortOrder: 'asc',
      perPage: 100,
    };

    const { data } = await this.client.get('/admin/organizations', { params });
    return data;
  }

  async getTerminalOrgs() {
    const { data } = await this.client.get('/admin/terminals');
    return data;
  }

  async getCefOrgs() {
    const { data } = await this.client.get('/admin/cefs');
    return data;
  }

  /**
   * Fetches info about all organizations
   *
   * @param {Object} criteria - search criteria
   * @return {Promise<Object>}
   */
  async searchOrgs(criteria) {
    const params = {
      sortField: criteria.sort.field,
      sortOrder: criteria.sort.order,
      page: criteria.page,
      perPage: criteria.perPage,
    };

    if (criteria.filter) {
      params.filter = criteria.filter;
    }
    if (typeof criteria.archived === 'boolean') {
      params.archived = criteria.archived;
    }

    const { data, headers } = await this.client.get('/admin/organizations', { params });
    return {
      data,
      pages: parseInt(headers['x-total-pages'], 10),
    };
  }

  /**
   * Fetches info about the given organization
   *
   * @param {String} id
   * @return {Promise<Object>}
   */
  async getOrgInfo(id) {
    const { data } = await this.client.get(`/admin/organizations/${id}`);
    return data;
  }

  /**
   *
   * @param {String} id
   * @param {Object} fields
   */
  async updateOrg(id, fields) {
    try {
      const { data } = await this.client.put(`/admin/organizations/${id}`, fields);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  async configureOrg(id, fields) {
    try {
      const { data } = await this.client.put(`/admin/organizations/${id}/configuration`, fields);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  /**
   *
   * @param {Object} fields
   */
  async createOrg(fields) {
    try {
      const { data } = await this.client.post('/admin/organizations', fields);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  /**
   *
   * @param {String} id
   */
  async archiveOrg(id) {
    const { data } = await this.client.delete(`/admin/organizations/${id}`);

    return data;
  }

  /**
   *
   * @param {String} id
   */
  async restoreOrg(id) {
    const { data } = await this.client.put(`/admin/organizations/${id}/restore`);
    return data;
  }

  /**
   * Fetches the status information from the server
   *
   * @return {Promise<Object>}
   */
  async getStatuses() {
    const { data } = await this.client.get('/statuses');
    return data;
  }

  /**
   * Fetches the containers
   *
   * @return {Promise<Object>}
   */
  async getContainers(criteria) {
    const params = {
      sortField: criteria.sort.field,
      sortOrder: criteria.sort.order,
      page: criteria.page,
      perPage: criteria.perPage,
      completed: criteria.completed,
    };

    if (criteria.filter) {
      params.filter = criteria.filter;
    }
    const { data, headers } = await this.client.get('/admin/containers', { params });
    return {
      data,
      pages: parseInt(headers['x-total-pages'], 10),
    };
  }

  async exportContainers(criteria) {
    const params = {
      initialDate: criteria.initialDate,
      finalDate: criteria.finalDate,
      exportCompletedContainers: criteria.exportCompletedContainers
    };
    return this.client.get('/admin/containers/export', { params });
  }

  /**
   * Fetches a container
   *
   * @param {String} id
   * @return {Promise<Object | null>}
   */
  async getContainer(id, status = null) {
    if (status) {
      const { data } = await this.client.get(`/admin/containers/${id}/edit/${status}`);
      return data;
    }
    const { data } = await this.client.get(`/admin/containers/${id}`);
    return data;
  }

  /**
   * Applies a status change to a container
   *
   * @param {Object} container
   * @param {String} status (internal key)
   * @param {Object} payload (w/ status fields)
   * @return {Promise<Object>}
   */
  async changeStatus(container, status, payload) {
    try {
      const { data } = await this.client.post(`/admin/containers/${container.id}/status`, {
        status,
        payload,
      });
      return data;
    } catch (e) {
      if (e.response && e.response.status === 422) {
        if (e.response.data.message.indexOf('required') !== -1) {
          throw new ApiValidationError('Please fill out the required fields');
        }
        throw new ApiValidationError(e.response.data.message);
      }
      throw e;
    }
  }

  logout() {
    this.client.defaults.headers.authorization = null;
  }

  async getNotifications() {
    const { data } = await this.client.get('/admin/notifications');
    return data;
  }

  async getNotification(id) {
    const { data } = await this.client.get(`/admin/notifications/${id}`);
    return data;
  }

  async markNotificationRead(id) {
    const {
      data: { unread },
    } = await this.client.put(`/admin/notifications/${id}/read`);
    return unread;
  }

  async markNotificationUnread(id) {
    const {
      data: { unread },
    } = await this.client.delete(`/admin/notifications/${id}/read`);
    return unread;
  }

  async markAsRead() {
    const {
      data: { unread },
    } = await this.client.put('/admin/notifications/read');
    return unread;
  }

  async subscribe(id) {
    const {
      data: { unread },
    } = await this.client.put(`/admin/containers/${id}/subscribed`);
    return unread;
  }

  async unsubscribe(id) {
    await this.client.delete(`/admin/containers/${id}/subscribed`);
  }

  async dismissNotification(id) {
    await this.client.delete(`/admin/notifications/${id}`);
  }

  async changeSetting(field, value) {
    await this.client.put('/user/notificationSettings', { [field]: value });
  }
}
