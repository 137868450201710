export default logToConsole => (error, logToAlert) => {
  if (logToConsole) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (logToAlert) {
    // TODO replace with modal or toast?
    // eslint-disable-next-line no-alert
    alert('An error has occurred');
  }
};
