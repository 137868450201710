const can = (permission, user) => {
  if (!user || !user.permissions) {
    return false;
  }

  if (user.permissions.indexOf(permission) !== -1) {
    return true;
  }

  if (permission.indexOf('*') !== -1) {
    const prefix = permission.replace('*', '');
    if (user.permissions.find(perm => perm.indexOf(prefix) === 0)) {
      return true;
    }
  }

  return false;
};

export const canChangeStatus = (statuses, user, container) => {
  const { permissions: { write } } = statuses[container.status];
  return (
    can(write, user)
      || can('CONTAINERS_WRITE', user)
      || can('CONTAINERS_WRITE_ORG', user)
  );
};

export default can;
