import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import DateTimeInput from '../../components/form/elements/DateTimeInput';
import ToggleInput from '../../components/form/elements/ToggleInput';

export default ({ onSubmit, ...props }) => createForm({
  fields: [
    {
      id: 'initialDate',
      label: 'From',
      inputComponent: DateTimeInput,
      inputOptions: {
        time: false,
      },
      required: true,
      validation: joi.date(),
      defaultValue: null,
    },
    {
      id: 'finalDate',
      label: 'To',
      inputComponent: DateTimeInput,
      inputOptions: {
        time: false,
      },
      required: true,
      validation: joi.date(),
      defaultValue: null,
    },
    {
      id: 'exportCompletedContainers',
      label: 'Export only containers at Terminal (End)',
      inputComponent: ToggleInput,
      required: false,
      validation: joi.boolean(),
      defaultValue: false,
    },
  ],
  layoutOptions: {
    submitText: 'Export',
  },
  onSubmit,
  ...props,
});
