import React from 'react';
import PropTypes from 'prop-types';
import AccordionBody from './AccordionBody';
import AccordionHeader from './AccordionHeader';

const buildClasses = classes => classes.filter(c => c).join(' ');

const AccordionSection = ({
  data, title, onSelect, visible, disabled, first, last, enableEdit, toURL
}) => (
  <div
    className={buildClasses([
      'accordion-section',
      disabled ? 'disabled' : '',
      visible ? 'selected' : '',
      first ? 'first' : '',
      last ? 'last' : '',
    ])}
  >
    <AccordionHeader title={title} onSelect={onSelect} disabled={disabled} enableEdit={enableEdit} toURL={toURL}/>
    {visible && <AccordionBody data={data} />}
  </div>
);

AccordionSection.propTypes = {
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AccordionSection;
