import { push } from 'connected-react-router';
import {
  CONTAINER_FETCH_FINISHED,
  CONTAINER_FETCH_FAILED,
  CONTAINER_STATUS_CHANGE_FINISHED,
  CONTAINER_STATUS_CHANGE_FAILED,
} from '../actionTypes';
import { ApiValidationError } from '../services/api';

const containerFetchFinished = container => ({
  type: CONTAINER_FETCH_FINISHED,
  container,
});

const containerFetchFailed = error => ({
  type: CONTAINER_FETCH_FAILED,
  error,
});

export const containerFetch = (id, status = null) => async (dispatch, getState, { api }) => {
  try {
    const container = await api.getContainer(id, status);
    dispatch(containerFetchFinished(container));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(containerFetchFailed(e));
  }
};

const containerStatusChangeFinished = container => ({
  type: CONTAINER_STATUS_CHANGE_FINISHED,
  container,
});

const containerStatusChangeFailed = error => ({
  type: CONTAINER_STATUS_CHANGE_FAILED,
  error,
});

export const statusChange = (container, status, fields, statuses) => async (dispatch, getState, { api }) => {
  try {
    const changed = await api.changeStatus(container, status, fields);
    dispatch(containerStatusChangeFinished(changed));
    const location = statuses[status] ? statuses[status].location : null;
    let reroute = `/container-inventory/${container.id}`
    if (location) {
      reroute += `?location=${encodeURI(location)}`
    }
    dispatch(push(reroute));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(containerStatusChangeFailed(e));
  }
};

export const containerSubscribe = id => async (dispatch, getState, { api }) => {
  await api.subscribe(id);
  // eslint-disable-next-line no-alert
  alert('Subscribed');
};

export const containerUnsubscribe = id => async (dispatch, getState, { api }) => {
  await api.unsubscribe(id);
  // eslint-disable-next-line no-alert
  alert('Unsubscribed');
};
