import { ORG_ADD_FINISHED, ORG_ADD_FAILED } from '../actionTypes';

export const getDefaultState = () => ({
  isLoading: false,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case ORG_ADD_FINISHED:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case ORG_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
