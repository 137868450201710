import React from 'react';
import PropTypes from 'prop-types';
import UserTable from './UserTable';

const ArchivedUserList = ({
  fetchUsers, onReset, onToggle, error,
}) => (
  <div className="user-list archived">
    {error && <p className="error-message">{error.message}</p>}
    <h1>Archived Users</h1>
    <UserTable fetchUsers={fetchUsers} onReset={onReset} onToggle={onToggle} archived />
  </div>
);

ArchivedUserList.defaultProps = {
  error: null,
};

ArchivedUserList.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};

export default ArchivedUserList;
