import React from 'react';
import { connect } from 'react-redux';
import ArchivedContainerList from './ArchivedContainerList';
import { containerListFetch } from '../../actions/containerList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  statuses: state.auth.archivedStatuses,
  containers: state.containerList.containers,
  isLoading: state.containerList.isLoading,
  error: state.containerList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchContainers: criteria => dispatch(
    containerListFetch({
      ...criteria,
      completed: true,
    }),
  ),
});

const ArchivedContainerListContainer = props => (
  <Screen name="containers" isLoading={false}>
    <ArchivedContainerList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchivedContainerListContainer);
