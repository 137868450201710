import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserEditForm from '../user-edit/UserEditForm';
import buildUrl from '../../utilities/buildUrl';

const UserAdd = ({ error, onSubmit, orgs }) => (
  <div className="user-edit">
    <div>
      <h1>Add User</h1>
    </div>
    <h2>User Information</h2>
    {error && <p className="error-message">{error.message}</p>}
    <UserEditForm onSubmit={onSubmit} orgs={orgs}>
      <Link to={buildUrl('users')} className="btn secondary">
        Cancel
      </Link>
    </UserEditForm>
  </div>
);

UserAdd.defaultProps = {
  error: null,
};

UserAdd.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  orgs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default UserAdd;
