import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const formatTimestamp = timestamp => moment(timestamp)
  .format('MM/DD/YY HH:mm');

const DateTime = ({ input, isEdited, editor, editedAtTime }) => {
  if (!input) return null;
  return <div 
            className={isEdited ? "date-time edited-value" : "date-time" }>
            {formatTimestamp(input)}
              {isEdited && <span className="tooltiptext">
                Last edited by user with id {editor || "N/A"} on {editedAtTime || "N/A"}
              </span>}
        </div>;
  //return <div className="date-time">{formatTimestamp(input)}</div>;
};

DateTime.defaultProps = {
  input: null,
  isEdited: false,
  editor: null
};

DateTime.propTypes = {
  input: PropTypes.string,
  isEdited: PropTypes.bool,
  editor: PropTypes.string,
};

export default DateTime;
