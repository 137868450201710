import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import './Login.scss';

import { CONTACT_EMAIL } from '../../constants';

const generateMailTo = (email, subject) => `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent(subject)}`;

const Login = ({ message, error, onSubmit }) => (
  <div className="Login">
    <div className="logo">
      <img src="/images/logo.png" alt="Port of Vancouver" className="Logo" />
    </div>
    <h1 className="LoginTitle">Blockchain prototype for shipping containers</h1>
    {error && <div className="error-message">{error.message}</div>}
    {!error && message && <div className="message">{message}</div>}
    <LoginForm onSubmit={onSubmit} />
    <div className="helpText">
      Please contact
      {' '}
      <a href={generateMailTo(CONTACT_EMAIL, 'Issues Logging Into Blockchain Prototype')}>
        {CONTACT_EMAIL}
      </a>
      {' '}
      {"if you're experiencing issues with logging in."}
    </div>
    <div className="disclaimer">
      The data on this portal has been prepared solely for the information and internal use of
      participating stakeholder organizations and may not be relied upon by any other person.
      Specifically, the information on this portal is not intended for the express or implied
      benefit of any third party and may not be relied upon for any purpose or in any manner by any
      third-party without the prior written consent of the &quot;CEF Blockchain Steering
      Committee.&quot; The data used is from third-party sources and has been taken &quot;as
      is&quot; and Deloitte has not independently verified, validated or audited the data. Deloitte
      makes no representations or warranties with respect to the accuracy of the information, nor
      whether it is suitable for the purposes to which it is put by users.
    </div>
  </div>
);

Login.defaultProps = {
  error: null,
  message: null,
};

Login.propTypes = {
  message: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default Login;
