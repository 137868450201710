import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import PasswordInput from '../../components/form/elements/PasswordInput';
import InlineFieldLayout from '../../components/form/InlineFieldLayout';

export default ({ onSubmit }) => createForm({
  fields: [
    {
      id: 'currentPassword',
      label: 'Current Password',
      inputComponent: PasswordInput,
      required: true,
      validation: joi.string().allow(''),
      fieldComponent: InlineFieldLayout,
      inputOptions: { placeholder: 'Current Password' },
    },
    {
      id: 'newPassword',
      label: 'New Password',
      inputComponent: PasswordInput,
      required: true,
      validation: joi.string().allow(''),
      fieldComponent: InlineFieldLayout,
      inputOptions: { placeholder: 'New Password' },
    },
    {
      id: 'confirmPassword',
      label: 'Confirm New Password',
      inputComponent: PasswordInput,
      required: true,
      validation: joi.string().allow(''),
      fieldComponent: InlineFieldLayout,
      inputOptions: { placeholder: 'Confirm New Password' },
    },
  ],
  layoutOptions: {
    submitText: 'Save Password',
  },
  onSubmit,
});
