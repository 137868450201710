import React from 'react';
import PropTypes from 'prop-types';
import ContainerTable from './ContainerTable';

const ContainerList = ({ fetchContainers, statuses }) => (
  <div className="container-list">
    <h1>Container List</h1>
    <ContainerTable fetchContainers={fetchContainers} statuses={statuses} />
  </div>
);

ContainerList.propTypes = {
  fetchContainers: PropTypes.func.isRequired,
  statuses: PropTypes.shape({}).isRequired,
};

export default ContainerList;
