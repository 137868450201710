import { createBrowserHistory } from 'history';
import {
  compose, createStore, applyMiddleware,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import reducers from './reducers';

import forcePasswordChange from './middleware/forcePasswordChange';
import authRedirects from './middleware/authRedirects';
import errorHandler from './middleware/errorHandler';
import refreshToken from './middleware/refreshToken';
import sessionPersistence from './middleware/sessionPersistence';
import { SESSION_REFRESH_FREQUENCY_MS } from './constants';

const history = createBrowserHistory();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default services => ({
  history,
  store: createStore(
    connectRouter(history)(reducers),
    composeEnhancers(applyMiddleware(
      thunk.withExtraArgument({ ...services, browserHistory: history }),
      routerMiddleware(history),
      forcePasswordChange,
      authRedirects,
      refreshToken(services, SESSION_REFRESH_FREQUENCY_MS),
      sessionPersistence(services),
      errorHandler(services.errorReporter),
    )),
  ),
});
