import { connect } from 'react-redux';
import Settings from './Settings';
import { userSettingChange } from '../../actions/userInfo';

const mapStateToProps = state => ({
  user: state.auth.account,
});

const mapDispatchToProps = dispatch => ({
  onSettingChange: (field, value) => dispatch(userSettingChange(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
