import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrgEditForm from './OrgEditForm';
import buildUrl from '../../utilities/buildUrl';

const OrgEdit = ({ error, org, onSubmit }) => (
  <div className="org-edit">
    <div>
      <h1>Edit Organization</h1>
    </div>
    <h2>Organization Information</h2>
    {error && <p className="error-message">{error.message}</p>}
    {org && (
      <OrgEditForm org={org} onSubmit={onSubmit}>
        <Link to={buildUrl('organizations', org.id)} className="btn secondary">Cancel</Link>
      </OrgEditForm>
    )}
  </div>
);
OrgEdit.defaultProps = {
  error: null,
};
OrgEdit.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  org: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default OrgEdit;
