import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import TextInput from '../../components/form/elements/TextInput';
import SelectInput from '../../components/form/elements/SelectInput';
import permissions from '../../permissions';

export default ({ org = {}, onSubmit, ...props }) => createForm({
  fields: [
    {
      id: 'legalName',
      label: 'Legal Name',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.legalName,
    },
    {
      id: 'commonName',
      label: 'Common Name',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.commonName,
    },
    {
      id: 'contact',
      label: 'Contact Name',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.contact,
    },
    {
      id: 'email',
      label: 'Contact Email',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.email,
    },
    {
      id: 'phoneNumber',
      label: 'Contact Phone Number',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.phoneNumber,
    },
    {
      id: 'address',
      label: 'Address',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.address,
    },
    {
      id: 'adminName',
      label: 'Admin Name',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.adminName,
    },
    {
      id: 'adminPhoneNumber',
      label: 'Admin Phone Number',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.adminPhoneNumber,
    },
    {
      id: 'adminEmail',
      label: 'Admin Email',
      inputComponent: TextInput,
      required: true,
      validation: joi.string(),
      defaultValue: org.adminEmail,
    },
    {
      id: 'roleType',
      label: 'Role',
      inputComponent: SelectInput,
      required: true,
      validation: joi.string(),
      inputOptions: {
        options: Object.keys(permissions).map(permission => ({
          value: permission,
          label: permissions[permission].label,
        })),
      },
      defaultValue: org.roleType,
    },
  ],
  layoutOptions: {
    submitText: 'Save',
  },
  onSubmit,
  ...props,
});
