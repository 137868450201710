import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileItem from '../../components/ProfileItem';
import { containerPropType } from '../../propTypes';

const ContainerProfile = ({ container }) => (
  <div className="container-information">
    <h2>Container Profile</h2>
    <Row>
      <Col xs={3}>
        <ProfileItem label="Container #" value={container.containerId} />
      </Col>
      <Col xs={3}>
        <ProfileItem label="File Number" value={container.fileNumber} />
      </Col>
      <Col xs={3}>
        <ProfileItem label="Shipping Line" value={container.shippingLine} />
      </Col>
    </Row>
    <Row>
      <Col xs={3}>
        <ProfileItem label="Container Size" value={container.containerSize} units="feet" />
      </Col>
      <Col xs={3}>
        <ProfileItem label="Container Type" value={container.containerType} />
      </Col>
      <Col xs={3}>
        <ProfileItem
          label="Container Refrigeration"
          value={container.containerTempValue ? container.containerTempValue : '--'}
          units={container.containerTempValue ? container.containerTempUnit : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={3}>
        <ProfileItem label="Dangerous Goods?" value={container.containerHazardous} />
      </Col>
    </Row>
  </div>
);

ContainerProfile.propTypes = {
  container: containerPropType.isRequired,
};

export default ContainerProfile;
