import React from 'react';
import PropTypes from 'prop-types';
import { PanelGroup, Panel } from 'react-bootstrap';
import { map, uniq } from 'lodash';
import DateTime from '../../components/DateTime';
import ProfileItem from '../../components/ProfileItem';
import './Summary.scss';

const renderType = (value, type) => {
  switch (type) {
    case 'datetime':
    case 'datetimeOptional':
    case 'datetimeHour':
    case 'date':
      return <DateTime input={value} />;
    default:
      return value || '-';
  }
};

const filterEmptyStatuses = (fields, container) => {
  let nullCount = 0;
  fields.forEach((field) => {
    if (container[field] === null) nullCount += 1;
  });
  return nullCount !== fields.length;
};

const buildFieldsObject = (statuses, location, container) => Object.keys(statuses)
  .reverse()
  .filter(
    statusKey => (statuses[statusKey].groupLocation || statuses[statusKey].location) === location,
  )
  .filter(statusKey => filterEmptyStatuses(Object.keys(statuses[statusKey].fields), container))
  .reduce(
    (out, statusKey) => ({
      ...out,
      [statuses[statusKey].groupLabel || statuses[statusKey].label]: {
        ...Object.keys(statuses[statusKey].fields)
          .filter(
            fieldKey => statuses[statusKey].fields[fieldKey]
                || statuses[statusKey].fields[fieldKey].renderWhenNull !== false,
          )
          .filter(field => statuses[statusKey].fields[field].display !== false)
          .reduce(
            (data, fieldKey) => [
              ...data,
              <ProfileItem
                key={fieldKey}
                label={statuses[statusKey].fields[fieldKey].label}
                value={
                    renderType(container[fieldKey], statuses[statusKey].fields[fieldKey].type) || ''
                  }
              />,
            ],
            Object.values(out[statuses[statusKey].groupLabel || statuses[statusKey].label] || {}),
          ),
      },
    }),
    {},
  );

const ContainerSummary = ({ container, statuses, location: activeLocation }) => {
  const locations = uniq(map(statuses, status => status.groupLocation || status.location));
  return (
    <PanelGroup
      key={activeLocation}
      accordion
      id="container-summary"
      defaultActiveKey={activeLocation}
    >
      {locations.reverse().map(location => (
        <Panel eventKey={location} key={location}>
          <Panel.Heading>
            <Panel.Title toggle>{location}</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            {map(buildFieldsObject(statuses, location, container), (fields, label) => (
              <div key={label}>
                <h3>{label}</h3>
                <div className="status-fields">{Object.values(fields)}</div>
              </div>
            ))}
          </Panel.Body>
        </Panel>
      ))}
    </PanelGroup>
  );
};

ContainerSummary.propTypes = {
  container: PropTypes.shape({}).isRequired,
  statuses: PropTypes.shape({}).isRequired,
  location: PropTypes.string,
};

ContainerSummary.defaultProps = {
  location: null,
};

export default ContainerSummary;
