import { combineReducers } from 'redux';

import auth from './auth';
import containerList from './containerList';
import containerView from './containerView';
import userList from './userList';
import userInfo from './userInfo';
import userAdd from './userAdd';
import orgList from './orgList';
import orgInfo from './orgInfo';
import orgAdd from './orgAdd';
import notifications from './notifications';

export default combineReducers({
  auth,
  containerList,
  containerView,
  userList,
  userInfo,
  userAdd,
  orgList,
  orgInfo,
  orgAdd,
  notifications,
});
