import { push } from 'connected-react-router';
import { USER_ADD_FINISHED, USER_ADD_FAILED } from '../actionTypes';
import { ApiValidationError } from '../services/api';

const userAddFinished = user => ({
  type: USER_ADD_FINISHED,
  user,
});

const userAddFailed = error => ({
  type: USER_ADD_FAILED,
  error,
});

export const userAdd = data => async (dispatch, getState, { api }) => {
  try {
    const user = await api.createUser(data);
    dispatch(userAddFinished(user));
    dispatch(push('/users'));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }

    dispatch(userAddFailed(e));
  }
};
