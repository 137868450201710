import { push } from 'connected-react-router';
import {
  ORG_INFO_FETCH_FINISHED,
  ORG_INFO_FETCH_FAILED,
  ORG_EDIT_FINISHED,
  ORG_EDIT_FAILED,
} from '../actionTypes';
import { ApiValidationError } from '../services/api';

const orgInfoFetchFinished = org => ({
  type: ORG_INFO_FETCH_FINISHED,
  org,
});

const orgInfoFetchFailed = error => ({
  type: ORG_INFO_FETCH_FAILED,
  error,
});

const orgEditFinished = org => ({
  type: ORG_EDIT_FINISHED,
  org,
});

const orgEditFailed = error => ({
  type: ORG_EDIT_FAILED,
  error,
});

export const orgInfoFetch = id => async (dispatch, getState, { api }) => {
  try {
    const org = await api.getOrgInfo(id);
    dispatch(orgInfoFetchFinished(org));
  } catch (e) {
    dispatch(orgInfoFetchFailed(e));
  }
};

export const orgEdit = (id, fields) => async (dispatch, getState, { api }) => {
  try {
    const org = await api.updateOrg(id, fields);
    dispatch(orgEditFinished(org));
    dispatch(push(`/organizations/${id}`));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }
    dispatch(orgEditFailed(e));
  }
};

export const orgConfigure = (id, fields) => async (dispatch, getState, { api }) => {
  try {
    const org = await api.configureOrg(id, fields);
    dispatch(orgEditFinished(org));
    dispatch(push(`/organizations/${id}`));
  } catch (e) {
    if (e instanceof ApiValidationError) {
      throw e;
    }
    dispatch(orgEditFailed(e));
  }
};

export const orgTerminalsFetch = () => async (dispatch, getState, { api }) => api.getTerminalOrgs();
export const orgCefsFetch = () => async (dispatch, getState, { api }) => api.getCefOrgs();
