import React from 'react';
import PropTypes from 'prop-types';
import { without } from 'lodash';
import AccordionSection from './AccordionSection';
import './Accordion.scss';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    const { defaultSelected, data } = this.props;
    this.state = {
      selected: defaultSelected || Object.keys(data),
    };
  }

  toggleGroup = (heading) => {
    const { selected: currentlySelected } = this.state;

    if (currentlySelected.indexOf(heading) === -1) {
      this.setState({
        selected: [...currentlySelected, heading],
      });
    } else {
      this.setState({
        selected: without(currentlySelected, heading),
      });
    }
  };

  render() {
    const { data, disabledGroups, enableEdit, labelStatusURLMap} = this.props;
    const { selected } = this.state;
    return (
      <div className="accordion">
        <div className="accordion-line" />
        {Object.keys(data).map((heading, i) => (
          <AccordionSection
            key={heading}
            title={heading}
            data={data[heading]}
            onSelect={() => this.toggleGroup(heading)}
            visible={selected.indexOf(heading) !== -1}
            disabled={disabledGroups.indexOf(heading) !== -1}
            first={i === 0}
            last={i === Object.keys(data).length - 1}
            enableEdit={enableEdit}
            toURL={labelStatusURLMap[heading]}
          />
        ))}
      </div>
    );
  }
}

Accordion.defaultProps = {
  defaultSelected: null,
  disabledGroups: [],
};

Accordion.propTypes = {
  // object two levels deep
  data: PropTypes.shape({}).isRequired,
  defaultSelected: PropTypes.arrayOf(PropTypes.string),
  disabledGroups: PropTypes.arrayOf(PropTypes.string),
};

export default Accordion;
