import React from 'react';
import PropTypes from 'prop-types';

const ContainerToggleNotifications = ({
  onSubscribe, onUnsubscribe, subscribed, subscribing,
}) => (
  <div className="actions">
    {subscribed ? (
      <button disabled={subscribing} type="button" className="btn secondary" onClick={onUnsubscribe}>
        {subscribing ? 'Saving...' : 'Disable Notifications'}
      </button>
    ) : (
      <button disabled={subscribing} type="button" className="btn secondary" onClick={onSubscribe}>
        {subscribing ? 'Saving...' : 'Enable Notifications'}
      </button>
    )}
  </div>
);

ContainerToggleNotifications.propTypes = {
  subscribed: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  subscribing: PropTypes.bool.isRequired,
};

export default ContainerToggleNotifications;
