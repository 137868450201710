import React from 'react';
import { connect } from 'react-redux';
import OrgInformation from './OrgInformation';
import { orgInfoFetch } from '../../actions/orgInfo';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  org: state.orgInfo.org,
  isLoading: state.orgInfo.isLoading,
  error: state.orgInfo.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrg: id => dispatch(orgInfoFetch(id)),
});

class OrgInformationContainer extends React.Component {
  componentDidMount() {
    const { id, fetchOrg } = this.props;
    fetchOrg(id);
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Screen name="orgs-information" isLoading={isLoading}>
        <OrgInformation {...this.props} />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgInformationContainer);
