import React from 'react';
import { connect } from 'react-redux';
import ArchivedOrgList from './ArchivedOrgList';
import { orgListFetch, orgRestore } from '../../actions/orgList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  orgs: state.orgList.orgs,
  isLoading: state.orgList.isLoading,
  error: state.orgList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrgs: criteria => dispatch(
    orgListFetch({
      ...criteria,
      archived: true,
    }),
  ),
  onToggle: id => dispatch(orgRestore(id)),
});

const ArchivedOrgListContainer = props => (
  <Screen name="Orgs" isLoading={false}>
    <ArchivedOrgList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchivedOrgListContainer);
