import React from 'react';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

const WrapperComponent = ({ children }) => <ul className="pagination">{children}</ul>;

const Page = ({ value, isActive, onClick }) => (
  <li className={isActive ? 'page-item active page' : 'page-item page'}>
    <button type="button" className="page-link" onClick={onClick} title={`Page ${value}`}>
      {value}
    </button>
  </li>
);

const Ellipsis = ({ onClick }) => (
  <li className="page-item ellipsis">
    <button type="button" className="page-link" onClick={onClick}>
      ...
    </button>
  </li>
);

const FirstPageLink = ({ onClick }) => (
  <li className="page-item first">
    <button type="button" className="page-link" onClick={onClick}>
      &laquo;
    </button>
  </li>
);

const PreviousPageLink = ({ onClick }) => (
  <li className="page-item previous">
    <button type="button" className="page-link" onClick={onClick} title="Previous Page">
      &lt;
    </button>
  </li>
);

const NextPageLink = ({ onClick }) => (
  <li className="page-item next">
    <button type="button" className="page-link" onClick={onClick} title="Next Page">
      &gt;
    </button>
  </li>
);

const LastPageLink = ({ onClick }) => (
  <li className="page-item last">
    <button type="button" className="page-link" onClick={onClick}>
      &raquo;
    </button>
  </li>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};

const UltimatePaginationBootstrap4 = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent,
});

export default UltimatePaginationBootstrap4;
