import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const DateTime = ({ input }) => <div className="date-time relative">{moment(input).fromNow()}</div>;

DateTime.defaultProps = {
  input: null,
};

DateTime.propTypes = {
  input: PropTypes.string,
};

export default DateTime;
