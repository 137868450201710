import qs from 'query-string';
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import DashboardContainer from './containers/dashboard/DashboardContainer';
import LoginContainer from './containers/login/LoginContainer';
import SettingsContainer from './containers/settings/SettingsContainer';
import ChangePasswordContainer from './containers/settings-change-password/ChangePasswordContainer';

import ContainerListContainer from './containers/container-list/ContainerListContainer';
import ArchivedContainerListContainer from './containers/container-list/ArchivedContainerListContainer';
import ContainerViewContainer from './containers/container-view/ContainerViewContainer';
import StatusChangeContainer from './containers/container-status-change/StatusChangeContainer';

import AnalyticsContainer from './containers/analytics/AnalyticsContainer';

import UserListContainer from './containers/users/UserListContainer';
import ArchivedUserListContainer from './containers/users/ArchivedUserListContainer';
import UserInformationContainer from './containers/users/UserInformationContainer';
import UserEditContainer from './containers/user-edit/UserEditContainer';
import UserAddContainer from './containers/user-add/UserAddContainer';

import OrgListContainer from './containers/organizations/OrgListContainer';
import ArchivedOrgListContainer from './containers/organizations/ArchivedOrgListContainer';
import OrgInformationContainer from './containers/organizations/OrgInformationContainer';
import OrgEditContainer from './containers/organization-edit/OrgEditContainer';
import OrgConfigureContainer from './containers/organization-configure/OrgConfigureContainer';
import OrgAddContainer from './containers/organization-add/OrgAddContainer';

import NotificationListContainer from './containers/notification-list/NotificationListContainer';
import NotificationLinkContainer from './containers/notification-link/NotificationLinkContainer';

import NotFound from './components/NotFound';
import AutoLayout from './components/layouts/AutoLayout';
import LoadingScreen from './components/LoadingScreen';
import AppError from './components/AppError';
import RoutePermissioned from './components/RoutePermissioned';
import './App.scss';

const App = ({
  user, error, isLoading, logout,
}) => (
  <AppError error={error}>
    {isLoading ? (
      <LoadingScreen />
    ) : (
      <AutoLayout user={user} logout={logout}>
        <Switch>
          <Route path="/" exact component={LoginContainer} />
          <Route path="/dashboard" exact component={DashboardContainer} />
          <Route
            path="/logout"
            exact
            render={() => {
              setImmediate(logout);
              return <div>Logging out...</div>;
            }}
          />
          <RoutePermissioned
            path="/analytics"
            exact
            component={AnalyticsContainer}
            permission="ANALYTICS_READ"
            user={user}
          />
          <Route path="/notifications" exact component={NotificationListContainer} />
          <Route
            path="/notifications/link/:id"
            exact
            render={({ match }) => <NotificationLinkContainer id={match.params.id} />}
          />
          <Route path="/settings" exact component={SettingsContainer} />
          <Route path="/settings/change-password" exact component={ChangePasswordContainer} />
          <RoutePermissioned
            path="/container-archive"
            exact
            component={ArchivedContainerListContainer}
            permission="CONTAINERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/container-inventory"
            exact
            component={ContainerListContainer}
            permission="CONTAINERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/container-inventory/:id"
            exact
            render={({ match, location }) => (
              <ContainerViewContainer
                id={match.params.id}
                location={qs.parse(location.search).location}
              />
            )}
            permission="CONTAINERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/container-archive/:id"
            exact
            render={({ match, location }) => (
              <ContainerViewContainer
                id={match.params.id}
                location={qs.parse(location.search).location}
              />
            )}
            permission="CONTAINERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/container-inventory/:id/change-status"
            exact
            render={({ match }) => <StatusChangeContainer id={match.params.id} />}
            permission="CONTAINERS_WRITE*"
            user={user}
          />
          <RoutePermissioned
            path="/container-inventory/:id/status/:statusKey/edit"
            exact
            render={({ match }) => <StatusChangeContainer id={match.params.id} statusKey={match.params.statusKey} />}
            permission="CONTAINERS_WRITE*"
            user={user}
          />
          <RoutePermissioned
            path="/users"
            exact
            component={UserListContainer}
            permission="USERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/users/archived"
            exact
            component={ArchivedUserListContainer}
            permission="USERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/users/add"
            exact
            component={UserAddContainer}
            permission="USERS_MANAGE_*"
            user={user}
          />
          <RoutePermissioned
            path="/users/:id"
            exact
            render={({ match }) => <UserInformationContainer id={match.params.id} />}
            permission="USERS_*"
            user={user}
          />
          <RoutePermissioned
            path="/users/:id/edit"
            exact
            render={({ match }) => <UserEditContainer id={match.params.id} />}
            permission="USERS_MANAGE_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations"
            exact
            component={OrgListContainer}
            permission="ORGS_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations/archived"
            exact
            component={ArchivedOrgListContainer}
            permission="ORGS_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations/add"
            exact
            component={OrgAddContainer}
            permission="ORGS_MANAGE_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations/:id"
            exact
            render={({ match }) => <OrgInformationContainer id={match.params.id} />}
            permission="ORGS_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations/:id/edit"
            exact
            render={({ match }) => <OrgEditContainer id={match.params.id} />}
            permission="ORGS_MANAGE_*"
            user={user}
          />
          <RoutePermissioned
            path="/organizations/:id/configure"
            exact
            render={({ match }) => <OrgConfigureContainer id={match.params.id} />}
            permission="ORGS_MANAGE_*"
            user={user}
          />
          <Route component={NotFound} />
        </Switch>
      </AutoLayout>
    )}
  </AppError>
);

App.defaultProps = {
  user: null,
  error: null,
};

App.propTypes = {
  user: PropTypes.shape({}),
  error: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default App;
