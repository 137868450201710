import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ProfileItem from '../../components/ProfileItem';
import NotificationSettingsForm from './NotificationSettingsForm';

import './Settings.scss';

const Settings = ({ user, onSettingChange, message }) => (
  <div className="user-settings">
    <h1>Settings</h1>
    {message && <div className="message">{message}</div>}
    <div className="user-information">
      <h2>User Information</h2>
      <Row>
        <Col xs={4}>
          <ProfileItem label="First Name" value={user.firstName} />
          <ProfileItem label="Last Name" value={user.lastName} />
        </Col>
        <Col xs={4}>
          <ProfileItem label="Email Address" value={user.email} />
          <ProfileItem label="Title *" value={user.organizationCommonName} />
        </Col>
      </Row>
    </div>

    <div className="password">
      <h2>Password</h2>
      <Row>
        <Col xs={4}>
          <ProfileItem label="Current Password" value="************" />
        </Col>
        <Col xs={4} />
      </Row>
      <Link to="/settings/change-password">
        <button type="button" className="btn">
          Change Password
        </button>
      </Link>
    </div>

    <div className="notifications">
      <h2>Notifications and Subscriptions</h2>
      <NotificationSettingsForm
        userSettings={user.notificationSettings}
        onChange={onSettingChange}
      />
    </div>
  </div>
);

Settings.propTypes = {
  user: PropTypes.shape({}).isRequired,
  onSettingChange: PropTypes.func.isRequired,
  message: PropTypes.string,
};

Settings.defaultProps = {
  message: null,
};

export default Settings;
