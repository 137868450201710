import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActiveTable from '../../components/active-table/ActiveTable';
import buildUrl from '../../utilities/buildUrl';

const OrgTable = ({ fetchOrgs, onToggle, archived }) => (
  <ActiveTable
    fetchData={fetchOrgs}
    headings={[
      {
        key: 'commonName',
        label: 'Organization',
        sortable: true,
        link: row => `/organizations/${row.id}`,
      },
      { key: 'contact', label: 'Contact', sortable: false },
      { key: 'email', label: 'Email', sortable: false },
      { key: 'phoneNumber', label: 'Phone #', sortable: false },
      { key: 'address', label: 'Address', sortable: false },
      { key: 'configure', label: '', sortable: false },
      { key: 'dropdown', label: '', sortable: false },
    ]}
    perPage={10}
    defaultSortField="commonName"
    searchPlaceholder="Search for Organization"
    getId={row => row.id}
    buildCells={data => data.map(org => [
      org.commonName,
      org.contact,
      org.email,
      org.phoneNumber,
      org.address,
      org.configured ? (
        ''
      ) : (
        <Link to={buildUrl('organizations', org.id, 'configure')}>Configure</Link>
      ),
      <div className="dropdown">
        <div className="dropdown-content">
          <div className="dropdown-option">
            <Link to={`/users/${org.id}`}>View Information</Link>
          </div>
          <div className="dropdown-option">
            <Link to={`/organizations/${org.id}/edit`}>Edit Organization</Link>
          </div>
          <div className="dropdown-option">
            <button type="button" onClick={() => onToggle(org.id)}>
              {archived ? 'Restore Organization' : 'Archive Organization'}
            </button>
          </div>
        </div>
      </div>,
    ])
    }
  />
);

OrgTable.propTypes = {
  fetchOrgs: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  archived: PropTypes.bool.isRequired,
};

export default OrgTable;
