import joi from 'joi-browser';
import createForm from '../../components/form/createForm';
import TextInput from '../../components/form/elements/TextInput';
import PasswordInput from '../../components/form/elements/PasswordInput';

export default ({ onSubmit }) => createForm({
  fields: [
    {
      id: 'email',
      label: 'Email',
      inputComponent: TextInput,
      required: true,
      validation: joi.string().allow(''),
    },
    {
      id: 'password',
      label: 'Password',
      inputComponent: PasswordInput,
      required: true,
      validation: joi.string().allow(''),
    },
  ],
  layoutOptions: {
    submitText: 'Login',
  },
  onSubmit,
});
