import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContainerView from './ContainerView';
import Screen from '../../components/Screen';
import {
  containerFetch,
  containerSubscribe,
  containerUnsubscribe,
} from '../../actions/containerView';

export class ContainerViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: null,
      subscribing: false,
    };
  }

  componentDidMount() {
    const { id, getContainer } = this.props;
    getContainer(id);
  }

  handleSubscribe = async () => {
    this.setState({ subscribing: true });
    const { container, onSubscribe } = this.props;
    try {
      await onSubscribe(container.id);
      this.setState({ subscribing: false, subscribed: true });
    } catch (e) {
      this.setState({ subscribing: false });
      // eslint-disable-next-line no-alert
      alert('Could not change notification settings');
    }
  };

  handleUnsubscribe = async () => {
    const { container, onUnsubscribe } = this.props;
    this.setState({ subscribing: true });
    try {
      await onUnsubscribe(container.id);
      this.setState({ subscribing: false, subscribed: false });
    } catch (e) {
      this.setState({ subscribing: false });
      // eslint-disable-next-line no-alert
      alert('Could not change notification settings');
    }
  };

  render() {
    const { location, isLoading, error: propError } = this.props;
    const { subscribing, subscribed } = this.state;

    const isSubscribed = (() => {
      if (typeof subscribed === 'boolean') {
        return subscribed;
      }
      if (this.props.container) {
        return this.props.container.userSubscribed;
      }
      return false;
    })();

    return (
      <Screen name="flagged container tracking history" isLoading={isLoading} error={propError}>
        <ContainerView
          {...this.props}
          subscribing={subscribing}
          subscribed={isSubscribed}
          onSubscribe={this.handleSubscribe}
          onUnsubscribe={this.handleUnsubscribe}
          location={location}
          onLocationChange={this.handleLocationChange}
        />
      </Screen>
    );
  }
}

ContainerViewContainer.defaultProps = {
  container: null,
};

ContainerViewContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  container: PropTypes.shape({}),
  getContainer: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const getStatuses = state => (state.containerView.container && state.containerView.container.masked
  ? state.auth.activeStatuses
  : state.auth.archivedStatuses);

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.containerView.isLoading,
  user: state.auth.account,
  container: state.containerView.container,
  statuses: getStatuses(state),
  canChangeStatus: !!(
    state.containerView.container
    && state.containerView.container.userCanChangeStatus
    && (!ownProps.location
      || ownProps.location === getStatuses(state)[state.containerView.container.status].location)
  ),
});

const mapDispatchToProps = dispatch => ({
  getContainer: id => dispatch(containerFetch(id)),
  onSubscribe: id => dispatch(containerSubscribe(id)),
  onUnsubscribe: id => dispatch(containerUnsubscribe(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerViewContainer);
