import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Table = ({
  headings, rawRows, rows, sort: { field, order }, onSortChange, keys,
}) => (
  <div className="active-table table">
    <table>
      <thead>
        <tr>
          {headings.map(({ key, label, sortable }) => (
            <th key={key} className={key}>
              {label}
              {sortable && (
                <React.Fragment>
                  {' '}
                  <button
                    type="button"
                    onClick={() => onSortChange(key)}
                    className={[
                      'sort',
                      key === field ? 'active' : 'inactive',
                      key === field && order === 'desc' ? 'desc' : 'asc',
                    ].join(' ')}
                  >
                    <span />
                  </button>
                </React.Fragment>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowI) => (
          <tr key={keys[rowI]}>
            {Object.keys(row).map((key, keyI) => (
              <td key={key} className={`col-${headings[keyI].key}`}>
                {headings[keyI].link ? (
                  <Link to={headings[keyI].link(rawRows[rowI])}>{row[key]}</Link>
                ) : (
                  <span>{row[key]}</span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

Table.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  rawRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default Table;
