import React from 'react';
import { connect } from 'react-redux';
import OrgEdit from './OrgEdit';
import Screen from '../../components/Screen';
import { orgInfoFetch, orgEdit } from '../../actions/orgInfo';

const mapStateToProps = state => ({
  org: state.orgInfo.org,
  isLoading: state.orgInfo.isLoading,
  error: state.orgInfo.error,
});

const mapDispatchToProps = dispatch => ({
  fetchOrg: id => dispatch(orgInfoFetch(id)),
  onSubmit: (id, data) => dispatch(orgEdit(id, data)),
});

class OrgEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const { id, fetchOrg } = this.props;
    fetchOrg(id);
  }

  handleSubmit = async (data) => {
    const { onSubmit, id } = this.props;
    try {
      await onSubmit(id, data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { isLoading, error: propError } = this.props;
    const { error: stateError } = this.state;
    return (
      <Screen name="org-edit" isLoading={isLoading} error={propError}>
        <OrgEdit {...this.props} onSubmit={this.handleSubmit} error={stateError} />
      </Screen>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgEditContainer);
