import React from 'react';
import PropTypes from 'prop-types';

const FieldLayout = ({
  id,
  className,
  label,
  type,
  description,
  required,
  errorMessages,
  warningMessages,
  children,
  inputId,
  maxErrors,
}) => {
  let wrappingClass = `input-field ${type} ${className}`;
  if (errorMessages.length || warningMessages.length) {
    wrappingClass += ' errors ';
  }
  const divId = `field-${id}`;
  const errors = errorMessages
    .map(message => ({
      message,
      errorClass: 'error-message',
    }))
    .concat(
      warningMessages.map(message => ({
        message,
        errorClass: 'warning-message',
      })),
    )
    .slice(0, maxErrors);

  return (
    <div id={divId} className={wrappingClass}>
      {label && (
        <label className="active" htmlFor={inputId}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      )}
      {children}
      {errors.map(({ message, errorClass }) => (
        <div key={message} className={errorClass}>
          {message}
        </div>
      ))}
      {description !== '' ? <div className="field-description">{description}</div> : null}
    </div>
  );
};

FieldLayout.displayName = 'FieldLayout';

FieldLayout.defaultProps = {
  className: '',
  label: '',
  description: '',
  errorMessages: [],
  warningMessages: [],
  required: false,
  maxErrors: 1,
};

FieldLayout.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  warningMessages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  maxErrors: PropTypes.number,
};

export default FieldLayout;
