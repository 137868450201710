import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import './UserInformation.scss';

import UserNotFound from './UserNotFound';
import buildUrl from '../../utilities/buildUrl';

const UserInformation = ({ user }) => {
  if (user) {
    return (
      <div className="user-view">
        <h1>View Information</h1>
        <div className="user-info">
          <UserProfile user={user} />
        </div>
        <Link to={buildUrl('users', user.id, 'edit')}>
          <button type="button" className="btn">
            Edit User
          </button>
        </Link>
      </div>
    );
  }
  return <UserNotFound />;
};

UserInformation.defaultProps = {
  user: null,
};

UserInformation.propTypes = {
  user: PropTypes.shape({}),
};

export default UserInformation;
