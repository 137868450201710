const unserialize = (payload) => {
  if (typeof payload === 'undefined') return null;
  return JSON.parse(payload);
};

export default class Storage {
  constructor(localStorage) {
    this.localStorage = localStorage;
  }

  get(key) {
    const stored = this.localStorage.getItem(key);
    if (!stored) return stored;
    try {
      return unserialize(stored);
    } catch (e) {
      // TODO apply the silent errorHandler here when the silent error handler has been added.
      return null;
    }
  }

  set(key, value) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  clear() {
    this.localStorage.clear();
  }
}
