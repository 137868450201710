export const getNextStatusKey = (container, statuses, status) => {
  const statusKeys = Object.keys(statuses);
  const currentIndex = statusKeys.indexOf(status);
  let i = 1;
  let nextIndex;
  let nextStatus;
  while (currentIndex + i < statusKeys.length) {
    nextIndex = currentIndex + i;
    nextStatus = statuses[statusKeys[nextIndex]];
    if (
      (nextStatus.conditionalOn
        && container[nextStatus.conditionalOn.field] === nextStatus.conditionalOn.value)
      || !nextStatus.conditionalOn
      || statuses[status].location === "At TCEF"
    ) {
      return statusKeys[nextIndex];
    }
    i += 1;
  }
  return null;
};

export const getNextStatus = (container, statuses, status) => statuses[getNextStatusKey(container, statuses, status)];

export const getNextStatusLabel = (container, statuses, status) => {
  const next = getNextStatus(container, statuses, status);
  if (next) {
    return next.label;
  }
  return null;
};

export const getAllNextStatusKeys = (container, statuses, status) => {
  const statusKeys = Object.keys(statuses);
  const currentIndex = statusKeys.indexOf(status);
  let i = 1;
  let nextIndex;
  let nextStatus;
  let nextStatusKeys = [];
  while (currentIndex + i < statusKeys.length) {
    nextIndex = currentIndex + i;
    nextStatus = statuses[statusKeys[nextIndex]];
    if (
      (nextStatus.conditionalOn
        && container[nextStatus.conditionalOn.field] === nextStatus.conditionalOn.value)
      || !nextStatus.conditionalOn
    ) {
      nextStatusKeys.push(statusKeys[nextIndex]);
    }
    i += 1;
  }
 
  return nextStatusKeys;
};

export const getAllNextStatuses = (container, statuses, status) => {
  const allNextStatusKeys = getAllNextStatusKeys(container, statuses, status) || [];
  return allNextStatusKeys.map((nextKey) => {
     return statuses[nextKey];
  })
}

