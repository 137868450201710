import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/themes/airbnb.css';

class DateTimeInput extends Component {
  static defaultProps = {
    value: '',
    placeholder: '',
    fieldSuffix: '',
    valid: true,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(dates) {
    const [date] = dates;
    const { id, onChange } = this.props;
    onChange(id, date ? date.toISOString() : null);
  }

  render() {
    const {
      id,
      value,
      placeholder,
      fieldSuffix,
      valid,
      onBlur,
      disabled,
      time,
      timeIntervals,
    } = this.props;
    const validClass = valid ? '' : 'invalid';
    return (
      <div className="datetime-input">
        <Flatpickr
          id={id}
          value={value ? moment(value).toDate() : undefined}
          options={{
            enableTime: time,
            minuteIncrement: timeIntervals,
            defaultDate: new Date(),
            defaultMinute: time ? moment().format('m') : null,
            defaultHour: time ? moment().format('H') : null,
            allowInput: !disabled,
          }}
          onChange={date => this.handleChange(date)}
          placeholder={placeholder}
          className={validClass}
          onBlur={onBlur}
          autoComplete="off"
        />
        {fieldSuffix !== '' ? <span className="field-suffix">{fieldSuffix}</span> : null}
      </div>
    );
  }
}

DateTimeInput.defaultProps = {
  disabled: false,
  time: true,
  timeIntervals: 1,
};

DateTimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  time: PropTypes.bool,
  timeIntervals: PropTypes.number,
};

DateTimeInput.displayName = 'DateTimeInput';

export default DateTimeInput;
