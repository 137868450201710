import {
  CONTAINER_FETCH_FINISHED,
  CONTAINER_FETCH_FAILED,
  CONTAINER_STATUS_CHANGE_FINISHED,
  CONTAINER_STATUS_CHANGE_FAILED
} from '../actionTypes';

export const getDefaultState = () => ({
  container: null,
  isLoading: true,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONTAINER_FETCH_FINISHED:
      return {
        ...state,
        container: action.container,
        isLoading: false,
        error: null,
      };

    case CONTAINER_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case CONTAINER_STATUS_CHANGE_FINISHED:
      return {
        ...state,
        container: action.container,
      };

    case CONTAINER_STATUS_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case 'LOGOUT':
      return getDefaultState();

    default:
      return state;
  }
};

export default reducer;
