import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl } from 'react-bootstrap';
import { debounce } from 'lodash';
import Table from './Table';
import Pagination from './Pagination';

import './ActiveTable.scss';

const switchOrder = order => (order === 'asc' ? 'desc' : 'asc');

class ActiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      pages: null,
      filter: '',
      sort: {
        field: props.defaultSortField,
        order: props.sortOrder,
      },
      page: 1,
    };
    this.mounted = false;
    this.handleDebouncedFilter = debounce(this.handleFilter, props.debounceMs);
  }

  componentDidMount() {
    this.mounted = true;
    this.reloadData(false);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSortChange = async (newSortField) => {
    if (!this.mounted) {
      return;
    }

    const {
      sort: { field, order },
    } = this.state;
    const newSortOrder = newSortField === field ? switchOrder(order) : 'asc';

    await new Promise(resolve => this.setState(
      {
        page: 1,
        sort: { field: newSortField, order: newSortOrder },
      },
      resolve,
    ));
    await this.reloadData(true);
  };

  handlePageChange = async (page) => {
    if (!this.mounted) {
      return;
    }
    await new Promise(resolve => this.setState({ page }, resolve));
    this.reloadData(true);
  };

  handleFilter = async (filter) => {
    if (!this.mounted) {
      return;
    }
    await new Promise(resolve => this.setState({ filter }, resolve));
    this.reloadData(true);
  };

  handleFilterChange = (event) => {
    if (!this.mounted) {
      return;
    }
    const filter = event.target.value;
    this.setState({ filter, page: 1 });
    this.handleDebouncedFilter(filter);
  };

  reloadData = async (reset = true) => {
    const { fetchData, perPage } = this.props;
    const { sort, page, filter } = this.state;
    if (reset) {
      if (!this.mounted) {
        return;
      }
      await new Promise(resolve => this.setState(
        {
          loading: true,
          data: null,
          error: null,
          pages: null,
        },
        resolve,
      ));
    }
    try {
      const { data, pages } = await fetchData({
        sort,
        page,
        perPage,
        filter,
      });
      if (!this.mounted) {
        return;
      }
      this.setState({ loading: false, data, pages });
    } catch (error) {
      if (!this.mounted) {
        return;
      }
      this.setState({ loading: false, error });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      loading, data, error, sort, page, pages, filter,
    } = this.state;
    const {
      buildCells, headings, getKey, searchPlaceholder,
    } = this.props;

    return (
      <div className="active-table">
        <div className="search-bar">
          <Form onSubmit={this.handleSubmit}>
            <FormControl
              type="text"
              placeholder={searchPlaceholder}
              value={filter}
              onChange={this.handleFilterChange}
            />
          </Form>
        </div>
        {loading && <h2>Loading...</h2>}
        {error && <div>{error.message}</div>}
        {!loading
          && !error && (
            <div>
              <Table
                headings={headings}
                sort={sort}
                keys={data.map(getKey)}
                rows={buildCells(data)}
                rawRows={data}
                onSortChange={this.handleSortChange}
              />
              {pages > 1 && (
                <div className="pagination-wrapper">
                  <Pagination
                    currentPage={page}
                    totalPages={pages}
                    onChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
        )}
      </div>
    );
  }
}

ActiveTable.defaultProps = {
  getKey: row => row.id,
  perPage: 25,
  debounceMs: 250,
  searchPlaceholder: 'Search',
  sortOrder: 'asc',
};

ActiveTable.propTypes = {
  debounceMs: PropTypes.number,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  buildCells: PropTypes.func.isRequired,
  defaultSortField: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  getKey: PropTypes.func,
  sortOrder: PropTypes.string,
};

export default ActiveTable;
