import { USER_INFO_FETCH_FINISHED, USER_INFO_FETCH_FAILED } from '../actionTypes';

export const getDefaultState = () => ({
  user: null,
  isLoading: true,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case USER_INFO_FETCH_FINISHED:
      return {
        ...state,
        user: action.user,
        isLoading: false,
        error: null,
      };

    case USER_INFO_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
