import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = ({ error, required, onSubmit }) => (
  <div id="password-form" className="change-password-form">
    <h1>Change Password</h1>
    {error ? (
      <p className="error-message">{error.message}</p>
    ) : (
      <p>
        Password must contain at least 6 characters, at least 1 capital letter, and at least 1
        special character.
      </p>
    )}
    {required && <p>You are required to change your password.</p>}
    <ChangePasswordForm onSubmit={onSubmit} />
  </div>
);

ChangePassword.defaultProps = {
  required: false,
  error: null,
};

ChangePassword.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  required: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePassword;
