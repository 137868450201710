import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import StatusChangeForm from './StatusChangeForm';
import buildUrl from '../../utilities/buildUrl';
import ContainerProfile from '../container-view/ContainerProfile';
import ContainerLocation from '../container-view/ContainerLocation';
import ContainerNotFound from '../container-view/ContainerNotFound';
import { containerPropType, statusesPropType, errorPropType } from '../../propTypes';

const StatusChange = ({
  container, statuses, onSubmit, error, statusKey
}) => {

  if (container && !container.userCanChangeStatus) {
    return <Redirect to={buildUrl('container-inventory', container.id)} />;
  }

  if (container) {
    return (
      <div className="container-view change-status">
        <div className="container-book-of-record">
          <h1>Tracking History - Change Status</h1>
        </div>

        <ContainerProfile container={container} />

        <ContainerLocation
          container={container}
          statuses={statuses}
          location={statuses[statusKey] ? statuses[statusKey].location : null}
          showNextStatus
        />

        <div className="bottom">
          {error && <p className="error-message">{error.message}</p>}
          <StatusChangeForm statuses={statuses} container={container} onSubmit={onSubmit} statusKey={statusKey}>
            <Link to={buildUrl('container-inventory', container.id)} className="btn secondary">
              Cancel
            </Link>
          </StatusChangeForm>
        </div>
      </div>
    );
  }

  return <ContainerNotFound />;
};

StatusChange.defaultProps = {
  error: null,
  container: null,
};

StatusChange.propTypes = {
  error: errorPropType,
  container: containerPropType,
  statuses: statusesPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StatusChange;
