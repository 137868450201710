import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrgConfigureForm from './OrgConfigureForm';
import buildUrl from '../../utilities/buildUrl';

const OrgConfigure = ({ error, org, onSubmit, cefs, terminals }) => (
  <div className="org-edit">
    <div>
      <h1>Configure Organization</h1>
    </div>
    <h2>Integration Details</h2>
    {(org.roleType === 'port' && (!cefs.length || !terminals.length)) ? (
      <div>
        <h3>Error</h3>
        <div>You can't make a port without any available terminals or CEFs. You can come back to this screen later.</div>
      </div>
    ) : (
      <div>
        {error && <p className="error-message">{error.message}</p>}
        {org && (
          <OrgConfigureForm org={org} onSubmit={onSubmit} terminals={terminals} cefs={cefs}>
            <Link to={buildUrl('organizations', org.id)} className="btn secondary">Cancel</Link>
          </OrgConfigureForm>
        )}
      </div>
    )}
  </div>
);
OrgConfigure.defaultProps = {
  error: null,
  terminals: null,
  cefs: null,
};
OrgConfigure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  org: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  terminals: PropTypes.arrayOf(PropTypes.shape({})),
  cefs: PropTypes.arrayOf(PropTypes.shape({})),
};
export default OrgConfigure;
