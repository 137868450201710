import { push } from 'connected-react-router';
import { LOGIN_FINISHED, SESSION_LOAD_FINISHED, LOGOUT } from '../actionTypes';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
const relevantActions = [
  LOCATION_CHANGE,
  SESSION_LOAD_FINISHED,
  LOGIN_FINISHED,
  LOGOUT,
];

/**
 * Forces users with a temporary password to change it
 * before they can use the app
 */
export default store => next => (action) => {
  next(action);

  // upon changing locations
  if (relevantActions.indexOf(action.type) !== -1) {
    const {
      auth: { account },
      router: { location: { pathname } },
    } = store.getState();

    // doesnt have a temporary password
    if (!account || !account.isTemporaryPassword) {
      return;
    }

    // prevent redirect loop
    if (action.type === LOCATION_CHANGE && action.payload.location.pathname === '/settings/change-password') {
      return;
    }

    if (pathname === '/logout') {
      return;
    }

    // issue redirect
    store.dispatch(push('/settings/change-password'));
  }
};
