import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Analytics from './Analytics';
import Screen from '../../components/Screen';
import { containerListFetch } from '../../actions/containerList';
import { containerExport } from '../../actions/containerExport';

const mapStateToProps = state => ({
  containers: state.containerList.containers,
});
const mapDispatchToProps = dispatch => ({
  fetchContainers: criteria => dispatch(
    containerListFetch({
      ...criteria,
      completed: false,
    }),
  ),
  onSubmit: (initialDate, finalDate, exportCompletedContainers) => dispatch(containerExport(initialDate, finalDate, exportCompletedContainers)),
});

class AnalyticsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  handleSubmit = async (dates) => {
    const { initialDate } = dates;
    let { finalDate } = dates;
    const { onSubmit } = this.props;
    const { exportCompletedContainers } = dates;
    // We want the whole day, so we set final date to 11:59 PM
    finalDate = moment(finalDate)
      .endOf('day')
      .toDate();
    try {
      await onSubmit(initialDate, finalDate, exportCompletedContainers);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <Screen name="analytics" isLoading={false} error={error}>
        <Analytics {...this.props} onSubmit={this.handleSubmit} />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsContainer);
