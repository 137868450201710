import React from 'react';
import { connect } from 'react-redux';
import UserEdit from './UserEdit';
import Screen from '../../components/Screen';
import { userInfoFetch, userEdit } from '../../actions/userInfo';
import { orgFetchAll } from '../../actions/orgList';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  fetchOrgs: () => dispatch(orgFetchAll()),
  fetchUser: id => dispatch(userInfoFetch(id)),
  onSubmit: (id, data) => dispatch(userEdit(id, data)),
});

class UserEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoadingUser: true,
      isLoadingOrgs: true,
      orgs: null,
      user: null,
    };
  }

  componentDidMount() {
    this.getUser();
    this.getOrgs();
  }

  getUser = async () => {
    try {
      const { id, fetchUser } = this.props;
      const user = await fetchUser(id);
      this.setState({
        user,
        isLoadingUser: false,
      });
    } catch (error) {
      this.setState({
        isLoadingUser: false,
        error,
      });
    }
  };

  getOrgs = async () => {
    try {
      const { fetchOrgs } = this.props;
      const orgs = await fetchOrgs();
      this.setState({
        orgs,
        isLoadingOrgs: false,
      });
    } catch (error) {
      this.setState({
        isLoadingOrgs: false,
        error,
      });
    }
  };

  handleSubmit = async (data) => {
    const { onSubmit, id } = this.props;
    try {
      await onSubmit(id, data);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const {
      isLoadingUser, isLoadingOrgs, user, orgs, error,
    } = this.state;

    return (
      <Screen name="user-edit" isLoading={isLoadingUser || isLoadingOrgs} error={error}>
        <UserEdit
          {...this.props}
          user={user || {}}
          orgs={orgs || []}
          onSubmit={this.handleSubmit}
        />
      </Screen>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEditContainer);
