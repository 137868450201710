import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrgEditForm from '../organization-edit/OrgEditForm';
import buildUrl from '../../utilities/buildUrl';

const OrgAdd = ({ error, onSubmit }) => (
  <div className="org-add">
    <div>
      <h1>Add Org</h1>
    </div>
    <h2>Org Information</h2>
    {error && <p className="error-message">{error.message}</p>}
    <OrgEditForm onSubmit={onSubmit}>
      <Link to={buildUrl('organizations')} className="btn secondary">Cancel</Link>
    </OrgEditForm>
  </div>
);

OrgAdd.defaultProps = {
  error: null,
};

OrgAdd.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default OrgAdd;
