import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { chunk } from 'lodash';
import ProfileItem from '../ProfileItem';

const AccordionBody = ({ data, columns, columnWidth }) => (
  <div className="accordion-body">
    {chunk(Object.keys(data), columns).map((group, i) => (
      // eslint-disable-next-line
      <Row key={i}>
        {group.map(key => (
          <Col key={key} xs={columnWidth}>
            <ProfileItem label={key} value={data[key]} />
          </Col>
        ))}
      </Row>
    ))}
  </div>
);

AccordionBody.defaultProps = {
  columns: 2,
  columnWidth: 6,
};

AccordionBody.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.number,
  columnWidth: PropTypes.number,
};

export default AccordionBody;
