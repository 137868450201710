import { go } from 'connected-react-router';
import {
  USER_PASSWORD_RESET_FINISHED,
  USER_PASSWORD_RESET_FAILED,
  USER_ARCHIVE_FINISHED,
  USER_ARCHIVE_FAILED,
  USER_RESTORE_FINISHED,
  USER_RESTORE_FAILED,
} from '../actionTypes';

const userPasswordResetFinished = () => ({
  type: USER_PASSWORD_RESET_FINISHED,
});

const userPasswordResetFailed = error => ({
  type: USER_PASSWORD_RESET_FAILED,
  error,
});

const userArchiveFinished = () => ({
  type: USER_ARCHIVE_FINISHED,
});

const userArchiveFailed = error => ({
  type: USER_ARCHIVE_FAILED,
  error,
});

const userRestoreFinished = () => ({
  type: USER_RESTORE_FINISHED,
});

const userRestoreFailed = error => ({
  type: USER_RESTORE_FAILED,
  error,
});

export const userListFetch = criteria => async (dispatch, getState, { api }) => api.getUsers(criteria);

export const userPasswordReset = id => async (dispatch, getState, { api }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const reset = confirm('Reset password?');
    if (reset) {
      await api.resetUserPassword(id);
      dispatch(userPasswordResetFinished());
      alert('Password reset!');
    }
  } catch (e) {
    dispatch(userPasswordResetFailed(e));
  }
};

export const userArchive = id => async (dispatch, getState, { api }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const archive = confirm('Archive user?');
    if (archive) {
      await api.archiveUser(id);
      dispatch(userArchiveFinished());
      alert('User archived!');
      dispatch(go('/users'));
    }
  } catch (e) {
    dispatch(userArchiveFailed(e));
  }
};

export const userRestore = id => async (dispatch, getState, { api }) => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const restore = confirm('Restore user?');
    if (restore) {
      await api.restoreUser(id);
      dispatch(userRestoreFinished());
      alert('User restored!');
      dispatch(go('/users/archived'));
    }
  } catch (e) {
    dispatch(userRestoreFailed(e));
  }
};
