import { sessionRefreshFinished, sessionRefreshFailed } from '../actions';

/**
 * Redirects users based on their permissions
 * users: prevent from going to guest routes
 * guests: prevent from going to protected routes
 */
export default ({ api }, refreshFrequencyMs) => ({ getState, dispatch }) => (next) => {
  setInterval(async () => {
    const {
      auth: { account },
    } = getState();
    if (!account) {
      return;
    }

    try {
      const session = await api.refreshSession();
      dispatch(sessionRefreshFinished(session));
    } catch (e) {
      sessionRefreshFailed(e);
    }
  }, refreshFrequencyMs);

  return action => next(action);
};
