import {
  LOGIN_FINISHED,
  SESSION_LOAD_FINISHED,
  USER_UPDATED,
  NOTIFICATION_FETCH_FAILED,
  NOTIFICATION_FETCH_FINISHED,
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_FINISHED,
  NOTIFICATIONS_MARK_READ_FINISHED,
  NOTIFICATION_MARK_READ_FINISHED,
  NOTIFICATION_MARK_UNREAD_FINISHED,
  NOTIFICATION_DISMISS_FINISHED,
} from '../actionTypes';

export const getDefaultState = () => ({
  unread: null,
  isLoading: true,
  error: null,
  notifications: [],
  notification: null,
});

const buildUrl = (notification) => {
  if (notification.data.containerId) {
    return `/container-inventory/${notification.data.containerId}`;
  }
  return '/';
};

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case SESSION_LOAD_FINISHED:
    case LOGIN_FINISHED:
    case USER_UPDATED:
      return {
        ...state,
        unread: action.account.notificationsUnread,
      };

    case NOTIFICATION_FETCH_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case NOTIFICATION_FETCH_FINISHED:
      return {
        ...state,
        error: null,
        notification: {
          ...action.notification,
          url: buildUrl(action.notification),
        },
        isLoading: false,
      };

    case NOTIFICATIONS_FETCH_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case NOTIFICATIONS_FETCH_FINISHED:
      return {
        ...state,
        error: null,
        notifications: action.notifications.map(notification => ({
          ...notification,
          url: buildUrl(notification),
        })),
        unread: action.notifications
          .filter(notification => notification.unread)
          .length,
        isLoading: false,
      };

    case NOTIFICATIONS_MARK_READ_FINISHED:
      return {
        ...state,
        unread: action.unread,
        notifications: state.notifications.map(notification => ({
          ...notification,
          unread: false,
        })),
      };

    case NOTIFICATION_MARK_READ_FINISHED:
      return {
        ...state,
        unread: action.unread,
        notifications: state.notifications.map(notification => ({
          ...notification,
          unread: notification.id === action.id ? false : notification.unread,
        })),
      };

    case NOTIFICATION_MARK_UNREAD_FINISHED:
      return {
        ...state,
        unread: action.unread,
        notifications: state.notifications.map(notification => ({
          ...notification,
          unread: notification.id === action.id ? true : notification.unread,
        })),
      };

    case NOTIFICATION_DISMISS_FINISHED:
      return {
        ...state,
        unread: action.unread,
        notifications: state.notifications.filter(notification => notification.id !== action.id),
      };

    default:
      return state;
  }
};

export default reducer;
