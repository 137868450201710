import React from 'react';
import { connect } from 'react-redux';
import StatusChange from './StatusChange';
import Screen from '../../components/Screen';
import { containerFetch, statusChange } from '../../actions/containerView';
import { getNextStatusKey } from '../../utilities/statuses';

class StatusChangeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
    this.mounted = false;
    this.isLoading = true;
  }

  componentDidMount() {
    const { id, fetchContainer, statusKey } = this.props;
    fetchContainer(id, statusKey);
    this.mounted = true;
    this.isLoading = false;
  }

  componentWillUnmount() {
    this.mounted = false;
    this.isLoading = true;
  }

  _getNextStatusKey(container, statuses, statusKey) {
      if(statusKey) {
        return statusKey;
      } else {
        return getNextStatusKey(container, statuses, container.status)
      }
  }

  handleSubmit = async (data) => {
    const { onSubmit, container, statuses, statusKey } = this.props;
    try {
      await onSubmit(container, this._getNextStatusKey(container, statuses, statusKey), data, statuses);
      if (this.mounted) {
        this.setState({ error: null });
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({ error });
      }
    }
  };
  
  render() {
    const { error: propError, statusKey } = this.props;
    const { error: stateError } = this.state;
    return (
      <Screen name="container" isLoading={this.isLoading} error={propError}>
        <StatusChange {...this.props} onSubmit={this.handleSubmit} error={stateError} statusKey={statusKey}/>
      </Screen>
    );
  }
}

const mapStateToProps = state => ({
  container: state.containerView.container,
  isLoading: state.containerView.isLoading,
  statuses:
    state.containerView.container && state.containerView.container.masked
      ? state.auth.activeStatuses
      : state.auth.archivedStatuses,
});

const mapDispatchToProps = dispatch => ({
  fetchContainer: (id, status) => dispatch(containerFetch(id, status)),
  onSubmit: (container, status, data, statuses) => dispatch(statusChange(container, status, data, statuses)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatusChangeContainer);
