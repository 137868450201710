import {
  USER_LIST_FETCH_FINISHED,
  USER_LIST_FETCH_FAILED,
  USER_RESTORE_FINISHED,
  USER_RESTORE_FAILED,
} from '../actionTypes';

export const getDefaultState = () => ({
  users: null,
  isLoading: true,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case USER_LIST_FETCH_FINISHED:
      return {
        ...state,
        users: action.users,
        isLoading: false,
        error: null,
      };

    case USER_LIST_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case USER_RESTORE_FINISHED:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case USER_RESTORE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
