import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

class FormLayout extends Component {
  componentDidMount = () => {
    const toggleField = document.getElementById("field-exportCompletedContainers")
    if (toggleField) {
      const label = toggleField.firstChild
      const toggleInput = toggleField.lastChild
      const checkbox = toggleInput.firstChild.firstChild

      toggleField.style.display = "flex"
      toggleField.style.flexDirection = "row-reverse"
      toggleField.style.justifyContent = "flex-end"
      toggleField.style.gap = "10px"
      toggleField.style.alignItems = "center"
      label.style.margin = "0px"
      checkbox.style.margin = "5px 0 0 0"
    }
  }
  
  render() { return (
  <form onSubmit={this.props.onSubmit} className={`form ${this.props.formClassName}`}>
    <div className="white-box item-box">
      <div className="inner">
        <div className="row form-row">{this.props.fieldComponents}</div>
        {this.props.children}
        <button disabled={this.props.disabled} className={this.props.buttonClassName} type="submit">
          {this.props.submitText}
        </button>
        <Spinner loading={this.props.submitting} />
      </div>
    </div>
  </form>)
}};

FormLayout.defaultProps = {
  buttonClassName: 'btn',
  submitText: 'Submit',
  submitting: false,
  disabled: false,
  children: '',
  formClassName: '',
};

FormLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  buttonClassName: PropTypes.string,
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  formClassName: PropTypes.string,
};

export default FormLayout;
