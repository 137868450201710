import React from 'react';
import PropTypes from 'prop-types';
import { map, uniq } from 'lodash';
import ProgressBar from './ProgressBar';
import './LocationStatusBar.scss';

const LocationStatusBar = ({ container, statuses, location }) => {
  const { status: currentStatus } = container;
  const statusLocation = statuses[currentStatus].location;
  const locations = uniq(map(statuses, status => status.location));
  const currentIndex = locations.indexOf(statusLocation);
  const lastStatus = Object.keys(statuses)[Object.keys(statuses).length - 1];

  const steps = locations.map((loc, i) => ({
    label: loc,
    selected: location ? (location === loc) : (statusLocation === loc),
    disabled: currentIndex < i,
    url: `/container-${container.status === lastStatus ? 'archive' : 'inventory'}/${container.id}?location=${encodeURIComponent(loc)}`,
  }));

  return <ProgressBar steps={steps} />;
};

LocationStatusBar.defaultProps = {
  location: null,
};

LocationStatusBar.propTypes = {
  location: PropTypes.string,
  container: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  statuses: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LocationStatusBar;
