import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '../../components/ToggleButton';

export const NOTIFICATION_CONTAINER_STATUS_CHANGE = 'containerStatusChange';
export const NOTIFICATION_CONTAINER_LOCATION_CHANGE = 'containerLocationChange';
export const NOTIFICATION_CONTAINER_ADDED = 'containerAdded';
export const NOTIFICATION_CONTAINER_COMPLETED = 'containerCompleted';

const settings = {
  [NOTIFICATION_CONTAINER_ADDED]: 'Is added to the platform',
  [NOTIFICATION_CONTAINER_COMPLETED]: 'Arrives back at the terminal',
  [NOTIFICATION_CONTAINER_STATUS_CHANGE]: 'Status is changed',
  [NOTIFICATION_CONTAINER_LOCATION_CHANGE]: 'Location is changed',
};

const NotificationSettingsForm = ({ userSettings, onChange }) => (
  <div>
    <h3>I want to receive emails:</h3>
    <ul className="notification-settings">
      <li>
        <div>
          <ToggleButton
            value={!!userSettings.email}
            onChange={value => onChange('email', value)}
          />
        </div>
        <p>For all containers based on my notification preferences below</p>
      </li>
    </ul>
    <h3>I want to receive notifications when any container:</h3>
    <ul className="notification-settings">
      {Object.keys(settings).map(setting => (
        <li key={setting} className={userSettings[setting] ? 'active' : ''}>
          <div>
            <ToggleButton
              value={!!userSettings[setting]}
              onChange={value => onChange(setting, value)}
            />
          </div>
          <p>{settings[setting]}</p>
        </li>
      ))}
    </ul>
  </div>
);

NotificationSettingsForm.propTypes = {
  userSettings: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NotificationSettingsForm;
