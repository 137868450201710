import PropTypes from 'prop-types';

export const containerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  shippingLine: PropTypes.string,
  containerType: PropTypes.string,
  containerHazardous: PropTypes.bool,
});

export const statusesPropType = PropTypes.objectOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    fields: PropTypes.objectOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
);

export const errorPropType = PropTypes.shape({
  message: PropTypes.string.isRequired,
});

export const userPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  organizationCommonName: PropTypes.string.isRequired,
});

export const orgPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  legalName: PropTypes.string.isRequired,
  commonName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  adminEmail: PropTypes.string.isRequired,
  adminName: PropTypes.string.isRequired,
  adminPhoneNumber: PropTypes.string.isRequired,
});

export const notificationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});
