import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ContainerViewAccordion from './ContainerViewAccordion';
import ContainerSummary from './ContainerSummary';
import ContainerProfile from './ContainerProfile';
import ContainerLocation from './ContainerLocation';
import ContainerNotFound from './ContainerNotFound';
import ContainerToggleNotifications from './ContainerToggleNotifications';
import buildUrl from '../../utilities/buildUrl';
import { getNextStatus } from '../../utilities/statuses';
import { containerPropType, userPropType, statusesPropType } from '../../propTypes';
import can from '../../utilities/can';

import './ContainerView.scss';

const showActionButton = (statuses, status, user) => {
   if(statuses[status].location === "At TCEF") {
    if(can('CONTAINERS_WRITE_ORG_CBSA', user) || (statuses[status].permissions && statuses[status].permissions.write 
      && can(statuses[status].permissions.write, user))) {
      return true
    } else {
      return false;
    }
   }
   return true;
}

const ContainerView = ({
  container,
  statuses,
  location,
  canChangeStatus,
  subscribed,
  subscribing,
  onSubscribe,
  onUnsubscribe,
  user,
}) => {
  if (container) {

    return (
      <div className="container-view">
        <div className="container-book-of-record">
          <h1>Tracking History</h1>
        </div>

        <ContainerToggleNotifications
          subscribed={subscribed}
          subscribing={subscribing}
          onSubscribe={onSubscribe}
          onUnsubscribe={onUnsubscribe}
        />

        <ContainerProfile container={container} />

        <ContainerLocation container={container} statuses={statuses} location={location} />

        {canChangeStatus
          && getNextStatus(container, statuses, container.status).buttonLabel && showActionButton(statuses, container.status, user) && (
            <Link to={buildUrl('container-inventory', container.id, 'change-status')}>
              <button type="button" className="btn">
                {getNextStatus(container, statuses, container.status).buttonLabel}
              </button>
            </Link>
        )}

        {container.status !== 'CONTAINER_PROCESS_COMPLETED' && (
          <ContainerViewAccordion
            statuses={statuses}
            container={container}
            location={location}
            user={user}
          />
        )}
        {container.status === 'CONTAINER_PROCESS_COMPLETED' && (
          <ContainerSummary statuses={statuses} container={container} location={location} />
        )}
      </div>
    );
  }
  return <ContainerNotFound />;
};

ContainerView.defaultProps = {
  container: null,
  user: null,
  location: null,
  canChangeStatus: false,
};

ContainerView.propTypes = {
  user: userPropType,
  container: containerPropType,
  statuses: statusesPropType.isRequired,
  location: PropTypes.string,
  canChangeStatus: PropTypes.bool,
  subscribed: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  subscribing: PropTypes.bool.isRequired,
};

export default ContainerView;
