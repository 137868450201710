import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import OrgNotFound from './OrgNotFound';
import buildUrl from '../../utilities/buildUrl';
import OrgProfile from './OrgProfile';

const OrgInformation = ({ org }) => {
  if (org) {
    return (
      <div className="org-view">
        <h1>View Information</h1>
        <div className="org-info">
          <h2>Organization Information</h2>
          <OrgProfile org={org} />
        </div>
        <Link to={buildUrl('organizations', org.id, 'edit')}>
          <button type="button" className="btn">
            Edit Organization
          </button>
        </Link>
      </div>
    );
  }
  return <OrgNotFound />;
};

OrgInformation.defaultProps = {
  org: null,
};

OrgInformation.propTypes = {
  org: PropTypes.shape({}),
};

export default OrgInformation;
