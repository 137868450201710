import {
  LOGIN_FINISHED,
  LOGIN_FAILED,
  LOGOUT,
  SESSION_START,
  SESSION_LOAD_START,
  SESSION_LOAD_FINISHED,
  SESSION_LOAD_FAILED,
  SESSION_REFRESH_FINISHED,
  USER_UPDATED,
} from '../actionTypes';

export const getDefaultState = () => ({
  account: null,
  session: null,
  statuses: null,
  activeStatuses: null,
  archivedStatuses: null,
  loading: true,
  error: null,
  expected: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case SESSION_LOAD_FINISHED:
      return {
        ...state,
        account: action.account,
        activeStatuses: action.statuses.active,
        archivedStatuses: action.statuses.archived,
        loading: false,
      };

    case SESSION_START:
      return {
        ...state,
        loading: false,
      };

    case SESSION_LOAD_START:
      return {
        ...state,
        session: action.session,
      };

    case SESSION_LOAD_FAILED:
      return {
        ...state,
        session: null,
        loading: false,
        error: action.error,
      };

    case SESSION_REFRESH_FINISHED:
      return {
        ...state,
        session: action.session,
      };

    case LOGIN_FINISHED:
      return {
        ...state,
        account: action.account,
        session: action.session,
        activeStatuses: action.statuses.active,
        archivedStatuses: action.statuses.archived,
        loading: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        expected: action.expected,
        loading: false,
      };

    case LOGOUT:
      return {
        ...state,
        account: null,
        session: null,
        error: null,
        expected: null,
        loading: false,
      };

    case USER_UPDATED: {
      return {
        ...state,
        account: action.account,
      };
    }

    case 'USER_SETTINGS_CHANGE_FINISHED':
      return {
        ...state,
        account: {
          ...state.account,
          notificationSettings: {
            ...state.account.notificationSettings,
            [action.field]: action.value,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
