import React from 'react';
import PropTypes from 'prop-types';

import ContainerExportForm from './ContainerExportForm';

const Analytics = ({ onSubmit }) => (
  <div className="analytics">
    <div>
      <h1>Data Export</h1>
    </div>
    <ContainerExportForm onSubmit={onSubmit} />
  </div>
);

Analytics.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Analytics;
