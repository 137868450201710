import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import App from './App';
import { logout } from './actions';

const mapStateToProps = state => ({
  user: state.auth.account ? {
    ...state.auth.account,
    notificationsUnread: state.notifications.unread,
  } : null,
  isLoading: !!state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

// TODO this is not efficient
// @link https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
