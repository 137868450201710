export default errorReporter => () => next => (action) => {
  try {
    next(action);
    if (action.error && !action.expected) {
      errorReporter(action.error);
    }
  } catch (e) {
    errorReporter(e);
  }
};
