import React from 'react';
import { connect } from 'react-redux';
import ContainerList from './ContainerList';
import { containerListFetch } from '../../actions/containerList';
import Screen from '../../components/Screen';

const mapStateToProps = state => ({
  statuses: state.auth.activeStatuses,
  containers: state.containerList.containers,
  isLoading: state.containerList.isLoading,
  error: state.containerList.error,
});

const mapDispatchToProps = dispatch => ({
  fetchContainers: criteria => dispatch(
    containerListFetch({
      ...criteria,
      completed: false,
    }),
  ),
});

const ContainerListContainer = props => (
  <Screen name="containers" isLoading={false}>
    <ContainerList {...props} />
  </Screen>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerListContainer);
