import React from 'react';
import PropTypes from 'prop-types';
import ActiveTable from '../../components/active-table/ActiveTable';
import DateTime from '../../components/DateTime';
import Duration from '../../components/Duration';

import './ContainerList.scss';

const ContainerTable = ({ fetchContainers, statuses, completed }) => (
  <ActiveTable
    fetchData={fetchContainers}
    headings={[
      {
        key: 'containerId',
        label: 'Container #',
        sortable: true,
        link: row => `/container-${completed ? 'archive' : 'inventory'}/${row.id}`,
      },
      { key: 'shippingLine', label: 'Shipping Line', sortable: false },
      { key: 'fileNumber', label: 'File #', sortable: false },
      { key: 'timestampLatest', label: 'Latest Update', sortable: true },
      { key: 'gctContainerDischargeTime', label: 'Duration Since Discharge', sortable: true },
      { key: 'cbsaSealApplicationTime', label: 'Examination Complete', sortable: true},
      { key: 'status', label: 'Location Status', sortable: false },
      { key: 'dataQualityChecks', label: 'To Action', sortable: true},
      { key: 'actionable', label: '', sortable: false },
    ]}
    perPage={10}
    defaultSortField="timestampLatest"
    sortOrder="desc"
    searchPlaceholder="Search for Container #, File #, Shipping Line, or Location Status..."
    getId={row => row.id}
    buildCells={data => data.map(container => [
      container.containerId,
      container.shippingLine,
      container.fileNumber,
      <DateTime input={container.timestampLatest} />,
      container.gctContainerDischargeTime ? (<Duration input={container.gctContainerDischargeTime} />) : ('--'),
      container.cbsaSealApplicationTime ? (<DateTime input={container.cbsaSealApplicationTime}/>) : ('--'),
      statuses[container.status].location,
      container.dataQualityChecks ? (container.dataQualityChecks.stakeholders).join(', ') : '',
      container.userSubscribed ? <img src="/images/flag.png" alt="Subscribed" /> : null,
    ])
    }
  />
);

ContainerTable.defaultProps = {
  completed: false,
};

ContainerTable.propTypes = {
  fetchContainers: PropTypes.func.isRequired,
  statuses: PropTypes.shape({}).isRequired,
  completed: PropTypes.bool,
};

export default ContainerTable;
