import React from 'react';
import PropTypes from 'prop-types';
import NotificationRow from './NotificationRow';
import { notificationPropType } from '../../propTypes';
import './Notifications.scss';

const Notifications = ({
  notifications,
  onNotificationRead,
  onNotificationUnread,
  onNotificationDismiss,
  onNotificationsRead,
  onNotificationClick,
}) => (
  <div className="notifications">
    <button type="button" className="txt" onClick={onNotificationsRead}>
       Mark All As Read
    </button>
    <h1>Notifications</h1>
    <ul>
      {notifications.map(notification => (
        <NotificationRow
          key={notification.id}
          notification={notification}
          onRead={() => onNotificationRead(notification.id)}
          onUnread={() => onNotificationUnread(notification.id)}
          onDismiss={() => onNotificationDismiss(notification.id)}
          onClick={() => onNotificationClick(notification)}
        />
      ))}
    </ul>
  </div>
);

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(notificationPropType).isRequired,
  onNotificationRead: PropTypes.func.isRequired,
  onNotificationUnread: PropTypes.func.isRequired,
  onNotificationDismiss: PropTypes.func.isRequired,
  onNotificationsRead: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
};

export default Notifications;
