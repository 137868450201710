import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ToggleInput extends Component {
  static defaultProps = {
    value: false,
    fieldSuffix: '',
    valid: true,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { checked } }) {
    const { id, onChange, onBlur } = this.props;

    onChange(id, checked);
    onBlur();
  }

  render() {
    const {
      id, value, valid, fieldSuffix,
    } = this.props;
    const validClass = valid ? '' : 'invalid';
    return (
      <div className="toggle-input">
        <div className="switch">
          <input
            type="checkbox"
            checked={value === true}
            id={id}
            className={validClass}
            onChange={this.handleChange}
          />
          <span className="lever" />
        </div>
        {fieldSuffix !== '' ? <span className="field-suffix">{fieldSuffix}</span> : null}
      </div>
    );
  }
}

ToggleInput.propTypes = {
  id: PropTypes.string.isRequired,
  fieldSuffix: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

ToggleInput.displayName = 'ToggleInput';

export default ToggleInput;
