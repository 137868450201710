import { ORG_INFO_FETCH_FINISHED, ORG_INFO_FETCH_FAILED } from '../actionTypes';

export const getDefaultState = () => ({
  org: null,
  isLoading: true,
  error: null,
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case ORG_INFO_FETCH_FINISHED:
      return {
        ...state,
        org: action.org,
        isLoading: false,
        error: null,
      };

    case ORG_INFO_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
