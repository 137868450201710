import { push } from 'connected-react-router';
import {
  NOTIFICATION_FETCH_FAILED,
  NOTIFICATION_FETCH_FINISHED,
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_FINISHED,
  NOTIFICATIONS_MARK_READ_FAILED,
  NOTIFICATIONS_MARK_READ_FINISHED,
  NOTIFICATION_MARK_READ_FAILED,
  NOTIFICATION_MARK_READ_FINISHED,
  NOTIFICATION_MARK_UNREAD_FAILED,
  NOTIFICATION_MARK_UNREAD_FINISHED,
  NOTIFICATION_DISMISS_FAILED,
  NOTIFICATION_DISMISS_FINISHED,
} from '../actionTypes';

const notificationsFetchFailed = error => ({
  type: NOTIFICATIONS_FETCH_FAILED,
  error,
});

const notificationsFetchFinished = notifications => ({
  type: NOTIFICATIONS_FETCH_FINISHED,
  notifications,
});

export const notificationListFetch = () => async (dispatch, getState, { api }) => {
  try {
    const notifications = await api.getNotifications();
    dispatch(notificationsFetchFinished(notifications));
  } catch (e) {
    dispatch(notificationsFetchFailed(e));
  }
};

const notificationFetchFailed = error => ({
  type: NOTIFICATION_FETCH_FAILED,
  error,
});

const notificationFetchFinished = notification => ({
  type: NOTIFICATION_FETCH_FINISHED,
  notification,
});

export const notificationFetch = id => async (dispatch, getState, { api }) => {
  try {
    const notification = await api.getNotification(id);
    dispatch(notificationFetchFinished(notification));
  } catch (e) {
    dispatch(notificationFetchFailed(id, e));
  }
};

const notificationsReadFailed = error => ({
  type: NOTIFICATIONS_MARK_READ_FAILED,
  error,
});

const notificationsReadFinished = () => ({
  type: NOTIFICATIONS_MARK_READ_FINISHED,
  unread: 0,
});

export const notificationsRead = () => async (dispatch, getState, { api }) => {
  try {
    await api.markAsRead();
    dispatch(notificationsReadFinished());
  } catch (e) {
    dispatch(notificationsReadFailed(e));
  }
};

export const notificationReadFailed = error => ({
  type: NOTIFICATION_MARK_READ_FAILED,
  error,
});

export const notificationReadFinished = (id, unread) => ({
  type: NOTIFICATION_MARK_READ_FINISHED,
  id,
  unread,
});

export const notificationRead = id => async (dispatch, getState, { api }) => {
  try {
    const unread = await api.markNotificationRead(id);
    dispatch(notificationReadFinished(id, unread));
  } catch (e) {
    dispatch(notificationReadFailed(e));
  }
};

export const notificationUnreadFailed = error => ({
  type: NOTIFICATION_MARK_UNREAD_FAILED,
  error,
});

export const notificationUnreadFinished = (id, unread) => ({
  type: NOTIFICATION_MARK_UNREAD_FINISHED,
  id,
  unread,
});

export const notificationUnread = id => async (dispatch, getState, { api }) => {
  try {
    const unread = await api.markNotificationUnread(id);
    dispatch(notificationUnreadFinished(id, unread));
  } catch (e) {
    dispatch(notificationUnreadFailed(e));
  }
};

export const notificationDismissFailed = error => ({
  type: NOTIFICATION_DISMISS_FAILED,
  error,
});

export const notificationDismissFinished = (id, unread) => ({
  type: NOTIFICATION_DISMISS_FINISHED,
  id,
  unread,
});

export const notificationDismiss = id => async (dispatch, getState, { api }) => {
  try {
    const unread = await api.dismissNotification(id);
    dispatch(notificationDismissFinished(id, unread));
  } catch (e) {
    dispatch(notificationDismissFailed(e));
  }
};

export const notificationClick = notification => async (dispatch) => {
  dispatch(notificationRead(notification.id));
  dispatch(push(notification.url));
};
