import React from 'react';
import PropTypes from 'prop-types';

// Helper function for displaying booleans
const checkBool = (input) => {
  if (typeof input === 'boolean') {
    if (input) {
      return 'Yes';
    }
    return 'No';
  }
  return input;
};

const ProfileItem = ({ label, value, units }) => (
  <div className="profile-data">
    <div className="profile-label">{label}</div>
    <div className="profile-value">
      {value !== null ? checkBool(value) : '--'}
      {units && ' '}
      {units}
    </div>
  </div>
);

ProfileItem.defaultProps = {
  units: '',
  value: null,
};

ProfileItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  units: PropTypes.string,
};

export default ProfileItem;
