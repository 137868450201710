export const APP_INIT = 'APP_INIT';
export const APP_SHUTDOWN = 'APP_SHUTDOWN';

export const SESSION_START = 'SESSION_START';
export const SESSION_LOAD_START = 'SESSION_LOAD_START';
export const SESSION_LOAD_FINISHED = 'SESSION_LOAD_FINISHED';
export const SESSION_LOAD_FAILED = 'SESSION_LOAD_FAILED';
export const SESSION_REFRESH_FINISHED = 'SESSION_REFRESH_FINISHED';
export const SESSION_REFRESH_FAILED = 'SESSION_REFRESH_FAILED';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FINISHED = 'LOGIN_FINISHED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

export const CONTAINER_LIST_FETCH_FINISHED = 'CONTAINER_LIST_FETCH_FINISHED';
export const CONTAINER_LIST_FETCH_FAILED = 'CONTAINER_LIST_FETCH_FAILED';

export const CONTAINER_FETCH_FINISHED = 'CONTAINER_FETCH_FINISHED';
export const CONTAINER_FETCH_FAILED = 'CONTAINER_FETCH_FAILED';

export const CONTAINER_STATUS_CHANGE_FINISHED = 'CONTAINER_STATUS_CHANGE_FINISHED';
export const CONTAINER_STATUS_CHANGE_FAILED = 'CONTAINER_STATUS_CHANGE_FAILED';

export const CONTAINER_EXPORT_FINISHED = 'CONTAINER_EXPORT_FINISHED';
export const CONTAINER_EXPORT_FAILED = 'CONTAINER_EXPORT_FAILED';

export const USER_LIST_FETCH_FINISHED = 'USER_LIST_FETCH_FINISHED';
export const USER_LIST_FETCH_FAILED = 'USER_LIST_FETCH_FAILED';

export const USER_INFO_FETCH_FINISHED = 'USER_INFO_FETCH_FINISHED';
export const USER_INFO_FETCH_FAILED = 'USER_INFO_FETCH_FAILED';

export const USER_EDIT_FINISHED = 'USER_EDIT_FINISHED';
export const USER_EDIT_FAILED = 'USER_EDIT_FAILED';

export const USER_ADD_FINISHED = 'USER_ADD_FINISHED';
export const USER_ADD_FAILED = 'USER_ADD_FAILED';

export const USER_PASSWORD_RESET_FINISHED = 'USER_PASSWORD_RESET_FINISHED';
export const USER_PASSWORD_RESET_FAILED = 'USER_PASSWORD_RESET_FAILED';
export const USER_UPDATED = 'USER_UPDATED';

export const USER_ARCHIVE_FINISHED = 'USER_ARCHIVE_FINISHED';
export const USER_ARCHIVE_FAILED = 'USER_ARCHIVE_FAILED';

export const USER_RESTORE_FINISHED = 'USER_RESTORE_FINISHED';
export const USER_RESTORE_FAILED = 'USER_RESTORE_FAILED';

export const ORG_LIST_FETCH_FINISHED = 'ORG_LIST_FETCH_FINISHED';
export const ORG_LIST_FETCH_FAILED = 'ORG_LIST_FETCH_FAILED';

export const ORG_INFO_FETCH_FINISHED = 'ORG_LIST_FETCH_FINISHED';
export const ORG_INFO_FETCH_FAILED = 'ORG_LIST_FETCH_FAILED';

export const ORG_EDIT_FINISHED = 'ORG_EDIT_FINISHED';
export const ORG_EDIT_FAILED = 'ORG_EDIT_FAILED';

export const ORG_ADD_FINISHED = 'ORG_ADD_FINISHED';
export const ORG_ADD_FAILED = 'ORG_ADD_FAILED';

export const ORG_ARCHIVE_FINISHED = 'ORG_ARCHIVE_FINISHED';
export const ORG_ARCHIVE_FAILED = 'ORG_ARCHIVE_FAILED';

export const ORG_RESTORE_FINISHED = 'ORG_RESTORE_FINISHED';
export const ORG_RESTORE_FAILED = 'ORG_RESTORE_FAILED';

export const NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED';
export const NOTIFICATION_FETCH_FINISHED = 'NOTIFICATION_FETCH_FINISHED';
export const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED';
export const NOTIFICATIONS_FETCH_FINISHED = 'NOTIFICATIONS_FETCH_FINISHED';
export const NOTIFICATIONS_MARK_READ_FAILED = 'NOTIFICATIONS_MARK_READ_FAILED';
export const NOTIFICATIONS_MARK_READ_FINISHED = 'NOTIFICATIONS_MARK_READ_FINISHED';
export const NOTIFICATION_MARK_READ_FAILED = 'NOTIFICATION_MARK_READ_FAILED';
export const NOTIFICATION_MARK_READ_FINISHED = 'NOTIFICATION_MARK_READ_FINISHED';
export const NOTIFICATION_MARK_UNREAD_FAILED = 'NOTIFICATION_MARK_UNREAD_FAILED';
export const NOTIFICATION_MARK_UNREAD_FINISHED = 'NOTIFICATION_MARK_UNREAD_FINISHED';
export const NOTIFICATION_DISMISS_FAILED = 'NOTIFICATION_FAILED';
export const NOTIFICATION_DISMISS_FINISHED = 'NOTIFICATION_DISMISS';
