import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserEditForm from './UserEditForm';
import buildUrl from '../../utilities/buildUrl';

const UserEdit = ({
  error, user, onSubmit, orgs,
}) => (
  <div className="user-edit">
    <div>
      <h1>Edit User</h1>
    </div>
    <h2>User Information</h2>
    {error && <p className="error-message">{error.message}</p>}
    <UserEditForm user={user} onSubmit={onSubmit} orgs={orgs}>
      <Link to={buildUrl('users', user.id)} className="btn secondary">
        Cancel
      </Link>
    </UserEditForm>
  </div>
);

UserEdit.defaultProps = {
  error: null,
};

UserEdit.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  orgs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default UserEdit;
