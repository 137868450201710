import React from 'react';
import PropTypes from 'prop-types';

const LoadingScreen = ({ message }) => (
  <div className="App container loading">
    <h1>{message}</h1>
  </div>
);

LoadingScreen.defaultProps = {
  message: 'Loading...',
};

LoadingScreen.propTypes = {
  message: PropTypes.string,
};

export default LoadingScreen;
